using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes
{
    public partial class RestServerConnection : IServerConnection
    {

		public void ProjectSettingsGet(Action<ProjectSettings> success, Action<string> error)
		{
			GetDataFunction<ProjectSettings>("/settings/project", (Action<ProjectSettings>)success, (Action<string>)error);
		}
		public void ProjectSettingsSet(ProjectSettings settings, Action<bool> success, Action<string> error)
		{
			PutDataFunction<bool>("/settings/project", settings,  (Action<bool>)success, (Action<string>)error);
		}
		public void SystemSettingsGet(Action<SystemSettings> success, Action<string> error)
		{
			GetDataFunction<SystemSettings>("/settings/system", (Action<SystemSettings>)success, (Action<string>)error);
		}
		public void SystemSettingsSet(SystemSettings settings, Action<bool> success, Action<string> error)
		{
			PutDataFunction<bool>("/settings/system",settings, (Action<bool>)success, (Action<string>)error);
        }
        public void TrendSettingsGet(Action<TrendDatabaseSettings> success, Action<string> error)
        {
            GetDataFunction<TrendDatabaseSettings>("/settings/trend", (Action<TrendDatabaseSettings>)success, (Action<string>)error);
        }
        public void TrendSettingsSet(TrendDatabaseSettings settings, Action<bool> success, Action<string> error)
        {
            PutDataFunction<bool>("/settings/trend", settings, (Action<bool>)success, (Action<string>)error);
        }

    }
}
