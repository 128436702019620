using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes.JSON
{
    public class DbSystemTreeBuilder : IJSONObjectConverter
    {
        public bool CanCovert(Type type)
        {
            return type.Equals(typeof(RGAVerl.VisuPlus2000net.Models.DbSystemTreeElement));
        }

        public class DbSystemTreeHelper : RGAVerl.VisuPlus2000net.Models.DbSystemTreeElement
        {
            public override SystemTreeItemTypes TreeItemType { set; get; }
        }

        public object GetObject(object jsonData, JSONBuilder jsonbuilder)
        {
            DbSystemTreeHelper helperObj = jsonbuilder.ConvertObject(jsonData, typeof(DbSystemTreeHelper))  as DbSystemTreeHelper;

            switch (helperObj.TreeItemType)
            {
                case SystemTreeItemTypes.FileProject:
                    return jsonbuilder.ConvertObject(jsonData, typeof(DbSystemTreeElementProject));
                case SystemTreeItemTypes.Root:
                    return jsonbuilder.ConvertObject(jsonData, typeof(DbSystemTreeElementRoot));
            }
            return null;


        }
    }
}
