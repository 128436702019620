using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace RGAVerl.VisuPlus2000net.Models
{
    public class DbSystemTreeElementRoot : DbSystemTreeElement
    {
        public override SystemTreeItemTypes TreeItemType
        {
            set { }
            get { return SystemTreeItemTypes.Root; }
        }
    }
}
