using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes
{
    public partial class RestServerConnection : IServerConnection
    {

        public void UserGetAll(Action<User[]> success, Action<string> error)
        {
            GetDataFunction<User[]>("/users", (Action<User[]>)success, (Action<string>)error);
        }

        public void UserGetById(int userId, Action<User> success, Action<string> error)
        {
            GetDataFunction<User>("/users/"+ userId, (Action<User>)success, (Action<string>)error);
        }

        public void UserAdd(User user, Action<int> success, Action<string> error)
        {
            PostDataFunction<int>("/users", user, (Action<int>)success, (Action<string>)error);
        }

        public void UserEdit(int userId, User user, Action<bool> success, Action<string> error)
        {
            PutDataFunction<bool>("/users/"+ userId, user, (Action<bool>)success, (Action<string>)error);
        }

        public void UserSetPassword(int userId, string password, Action<bool> success, Action<string> error)
        {
            PutDataFunction<bool>("/users/" + userId + "/password", password, (Action<bool>)success, (Action<string>)error);
        }

        public void UserDelete(int userId, Action<bool> success, Action<string> error)
        {
            DeleteDataFunction<bool>("/users/" + userId, (Action<bool>)success, (Action<string>)error);
        }



		public void UserSessionsGetAll(Action<UserSavedSession[]> success, Action<string> error)
		{
			GetDataFunction<UserSavedSession[]>("/users/sessions", (Action<UserSavedSession[]>)success, (Action<string>)error);
		}
		public void UserSessionsGetAllForUser(int userId, Action<UserSavedSession[]> success, Action<string> error)
		{
			GetDataFunction<UserSavedSession[]>("/users/" + userId+"/sessions", (Action<UserSavedSession[]>)success, (Action<string>)error);
		}
		public void UserSessionsDeleteAll(Action<bool> success, Action<string> error)
		{
			DeleteDataFunction<bool>("/users/sessions", (Action<bool>)success, (Action<string>)error);
		}
		public void UserSessionsDeleteBySessionId(string sessionid, Action<bool> success, Action<string> error)
		{
			DeleteDataFunction<bool>("/users/sessions/" + sessionid, (Action<bool>)success, (Action<string>)error);
		}
		public void UserSessionsDeleteAllForUser(int userId, Action<bool> success, Action<string> error)
		{
			DeleteDataFunction<bool>("/users/" + userId+ "/sessions", (Action<bool>)success, (Action<string>)error);
		}
		public void UserSessionsDeleteSessionForUser(int userid, string sessionid, Action<bool> success, Action<string> error)
		{
			DeleteDataFunction<bool>("/users/" + userid+ "/sessions/" + sessionid, (Action<bool>)success, (Action<string>)error);
		}


    }
}
