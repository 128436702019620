using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes
{
    public partial class RestServerConnection : IServerConnection
    {
        public void CommunicationStationsGetAllNames(Action<string[]> success, Action<string> error)
        {
            GetDataFunction<string[]>("/communication/stationnames", (Action<string[]>)success, (Action<string>)error);
        }

        public void CommunicationStationsGetAll(Action<StationSettings[]> success, Action<string> error)
        {
            GetDataFunction<StationSettings[]>("/communication/stations", (Action<StationSettings[]>)success, (Action<string>)error);
        }

        public void CommunicationStationsGetById(int id, Action<StationSettings> success, Action<string> error)
        {
            GetDataFunction<StationSettings>("/communication/stations/" + id, (Action<StationSettings>)success, (Action<string>)error);
        }

        public void CommunicationStationsAdd(StationSettings stationSettings, Action<int> success, Action<string> error)
        {
            PostDataFunction<int>("/communication/stations", stationSettings, (Action<int>)success, (Action<string>)error);
        }

        public void CommunicationStationsEdit(int id, StationSettings stationSettings, Action<bool> success, Action<string> error)
        {
            PutDataFunction<bool>("/communication/stations/" + id, stationSettings, (Action<bool>)success, (Action<string>)error);
        }

        public void CommunicationStationsDelete(int id, Action<bool> success, Action<string> error)
        {
            DeleteDataFunction<bool>("/communication/stations/" + id, (Action<bool>)success, (Action<string>)error);
        }
    }

}

