using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes
{
    public partial class RestServerConnection : IServerConnection
    {

        public void SessionSystemTreeLoadForUser(Action<bool> success, Action<string> error)
        {
            GetDataFunction<bool>("/session/loadsystemtree", (Action<bool>)success, (Action<string>)error);
        }
        public void SessionSystemTreeGetForUser(Action<SystemTreeItem[]> success, Action<string> error)
        {
            GetDataFunction<SystemTreeItem[]>("/session/systemtree", (Action<SystemTreeItem[]>)success, (Action<string>)error);
        }



        public void SystemTreeListGet(Action<DbSystemTreeList[]> success, Action<string> error)
        {
            GetDataFunction<DbSystemTreeList[]>("/systemtree", (Action<DbSystemTreeList[]>)success, (Action<string>)error);
        }
        public void SystemTreeElementGetById(int id, Action<DbSystemTreeElement> success, Action<string> error)
        {
            GetDataFunction<DbSystemTreeElement>("/systemtree/element/" + id, (Action<DbSystemTreeElement>)success, (Action<string>)error);
        }
        public void SystemTreeElementGetMotherById(int motherId, Action<DbSystemTreeElement> success, Action<string> error)
        {
            GetDataFunction<DbSystemTreeElement>("/systemtree/mother/"+ motherId, (Action<DbSystemTreeElement>)success, (Action<string>)error);
        }
        public void SystemTreeElementAdd(DbSystemTreeElement element, Action<int> success, Action<string> error)
        {
            PostDataFunction<int>("/systemtree", element, (Action<int>)success, (Action<string>)error);
        }
        public void SystemTreeElementUpdate(int id, DbSystemTreeElement element, Action<bool> success, Action<string> error)
        {
            PutDataFunction<bool>("/systemtree/element/" +id, element, (Action<bool>)success, (Action<string>)error);
        }
        public void SystemTreeElementDelete(int id, Action<bool> success, Action<string> error)
        {
            DeleteDataFunction<bool>("/systemtree/element/"+id, (Action<bool>)success, (Action<string>)error);
        }
        
        /* systemtree link*/
        public void SystemTreeLinkGetAll(Action<SystemTreeLink[]> success, Action<string> error)
        {
            GetDataFunction<SystemTreeLink[]>("/systemtree/link", (Action<SystemTreeLink[]>)success, (Action<string>)error);
        }
        public void SystemTreeLinkGetById(int id, Action<SystemTreeLink> success, Action<string> error)
        {
            GetDataFunction<SystemTreeLink>("/systemtree/link/" + id, (Action<SystemTreeLink>)success, (Action<string>)error);
        }
        public void SystemTreeLinkAdd(SystemTreeLink link, Action<int> success, Action<string> error)
        {
            PostDataFunction<int>("/systemtree/link", link, (Action<int>)success, (Action<string>)error);
        }
        public void SystemTreeLinkUpdate(int id, SystemTreeLink link, Action<bool> success, Action<string> error)
        {
            PutDataFunction<bool>("/systemtree/link/" + id, link, (Action<bool>)success, (Action<string>)error);
        }
        public void SystemTreeLinkDelete(int id, Action<bool> success, Action<string> error)
        {
            DeleteDataFunction<bool>("/systemtree/link/" + id, (Action<bool>)success, (Action<string>)error);
        }
        public void SystemTreeLinkGetWindowId(string id, Action<string> success, Action<string> error)
        {
            GetTextFunction("/systemtree/link/" + id + "/windowid", (Action<string>)success, (Action<string>)error);
        }
    }
}
