namespace RGAVerl.VisuPlus2000net.Models
{
    //fault with type for update
    public class FaultUpdateFault
    {
        public FaultTypes Type;
        public FaultListFault Fault { set; get; }

        public FaultUpdateFault(FaultListFault fault)
        {
            Fault = fault;
        }
    }
}
