using System.ComponentModel.DataAnnotations;

namespace RGAVerl.VisuPlus2000net.Models
{
    public class NotificationServiceMail : NotificationService
    {
        public override NotificationServiceProvider ServiceType
        {
            set { }
            get
            {
                return NotificationServiceProvider.Mail;
            }
        }
        /* Server Settings */

        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Server { set; get; }
        public int Port { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Login { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Password { set; get; }
        public bool Encryption { set; get; }

        /* Mail Settings */
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Subject { set; get; }
        public bool SubjectHasTime { set; get; }
        public bool SubjectHasFaultText { set; get; }
        public bool SubjectHasState { set; get; }

        public bool MessageHasTime { set; get; }
        public bool MessageHasSubject { set; get; }
        public bool MessageHasFaultText { set; get; }
        public bool MessageHasQuitState { set; get; }
        public bool MessageHasFaultState { set; get; }
        public bool MessageHasInfoText { set; get; }
        public bool MessageHasCustomText { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string MessageCustomText { set; get; }
    }
}
