
namespace RGAVerl.VisuPlus2000net.Models
{
    /// <summary>
    /// a fault for an user with different infotext color or quittable state
    /// </summary>
    public class SessionUserFault
    {
        public FaultListFault ListItem { set; get; }
        public string Text { set; get; }
        public string Color { set; get; }
        public string InfoText { set; get; }
        public bool Quittable { set; get; }

        public SessionUserFault()
        {

        }

        public SessionUserFault(FaultListFault item)
        {
            ListItem = item;
            Text = "Unbekannte Sörung: " + ListItem.Id.ToString();
            InfoText = string.Empty;
            Color = "black";
            Quittable = true;
        }
    }
}
