using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes
{
    public partial class RestServerConnection : IServerConnection
    {

        public void FaultListGetAllForUser(Action<SessionUserFault[]> success, Action<string> error)
        {
            GetDataFunction<SessionUserFault[]>("/faultlist/allforuser", (Action<SessionUserFault[]>)success, (Action<string>)error);
        }

        public void FaultListGetUpdate(int key, Action<SessionFaultUpdate> success, Action<string> error)
        {
            GetDataFunction<SessionFaultUpdate>("/faultlist/update/" +key, (Action<SessionFaultUpdate>)success, (Action<string>)error);
        }

        public void FaultListQuit(FaultId faultId, Action<bool> success, Action<string> error)
        {
            PostDataFunction<bool>("/faultlist/quit", faultId,(Action<bool>)success, (Action<string>)error);
        }

        public void FaultListDelete(FaultId faultId, Action<bool> success, Action<string> error)
        {
            PostDataFunction<bool>("/faultlist/delete", faultId, (Action<bool>)success, (Action<string>)error);
        }

    }
}
