using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes.JSON
{
    public class CommSymbolBuilder : IJSONObjectConverter
    {
        public bool CanCovert(Type type)
        {
            return type.Equals(typeof(RGAVerl.VisuPlus2000net.Models.CommunicationSymbol));
        }

        public class CommSymbolHelper : RGAVerl.VisuPlus2000net.Models.CommunicationSymbol
        {
            public override CommunicationSymbolTypes SymbolType { set; get; }

            public override string Information { set; get; }
        }

        public object GetObject(object jsonData, JSONBuilder jsonbuilder)
        {
            CommSymbolHelper helperObj = jsonbuilder.ConvertObject(jsonData, typeof(CommSymbolHelper))  as CommSymbolHelper;

            switch (helperObj.SymbolType)
            {
                case CommunicationSymbolTypes.Regular:
                    return jsonbuilder.ConvertObject(jsonData, typeof(CommunicationSymbolRegular));
                case CommunicationSymbolTypes.Ads:
                    return jsonbuilder.ConvertObject(jsonData, typeof(CommunicationSymbolAds));
                case CommunicationSymbolTypes.ModbusDouble:
                    return jsonbuilder.ConvertObject(jsonData, typeof(CommunicationSymbolModbusDouble));
            }
            return null;


        }
    }
}
