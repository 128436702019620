using System.ComponentModel.DataAnnotations;

namespace RGAVerl.VisuPlus2000net.Models
{
    public class StationSettingsBacnetAds : StationSettings
    {
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string AmsNetId { set; get; }
        public int Port { set; get; }
        

        public override StationProvider StationType
        {
            set { }
            get
            {
                return StationProvider.BacnetAds;
            }
        }
        

        public override string ProviderInfo
        {
            set { }
            get { return "AmsNetId: " + AmsNetId + " Port:" + Port; }
        }
    }
}
