using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes
{
    public partial class RestServerConnection : IServerConnection
    {
        public void FaultRoutineStationsGetAll(Action<FaultRoutineStation[]> success, Action<string> error)
        {
            GetDataFunction<FaultRoutineStation[]>("/communication/fault", (Action<FaultRoutineStation[]>)success, (Action<string>)error);
        }

        public void FaultRoutineStationsGetById(int id, Action<FaultRoutineStation> success, Action<string> error)
        {
            GetDataFunction<FaultRoutineStation>("/communication/fault/+"+id, (Action<FaultRoutineStation>)success, (Action<string>)error);
        }

        public void FaultRoutineStationsAdd(FaultRoutineStation stationSettings, Action<int> success, Action<string> error)
        {
            PostDataFunction<int>("/communication/fault", stationSettings, (Action<int>)success, (Action<string>)error);
        }

        public void FaultRoutineStationsEdit(int id, FaultRoutineStation stationSettings, Action<bool> success, Action<string> error)
        {
            PutDataFunction<bool>("/communication/fault/" +id, stationSettings, (Action<bool>)success, (Action<string>)error);
        }

        public void FaultRoutineStationsDelete(int id, Action<bool> success, Action<string> error)
        {
            DeleteDataFunction<bool>("/communication/fault/" +id, (Action<bool>)success, (Action<string>)error);
        }
    }
}
