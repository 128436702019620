using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes
{
    public partial class RestServerConnection : IServerConnection
    {

        public void EnergyManagementItemAll(Action<HeiztageItem[]> success, Action<string> error)
        {
            GetDataFunction<HeiztageItem[]>("/energymngmt", (Action<HeiztageItem[]>)success, (Action<string>)error);
        }
        public void EnergyManagementItemById(int id, Action<HeiztageItem> success, Action<string> error)
        {
            GetDataFunction<HeiztageItem>("/energymngmt/" + id, (Action<HeiztageItem>)success, (Action<string>)error);
        }
        public void EnergyManagementItemAdd(HeiztageItem item, Action<int> success, Action<string> error)
        {
            PostDataFunction<int>("/energymngmt", item, (Action<int>)success, (Action<string>)error);
        }
        public void EnergyManagementItemUpdate(int id, HeiztageItem item, Action<bool> success, Action<string> error)
        {
            PutDataFunction<bool>("/energymngmt/" + id, item, (Action<bool>)success, (Action<string>)error);
        }
        public void EnergyManagementItemDelete(int id, Action<bool> success, Action<string> error)
        {
            DeleteDataFunction<bool>("/energymngmt/" + id, (Action<bool>)success, (Action<string>)error);
        }
        public void EnergyManagementItemData(int id, Action<HeiztageDataItem[]> success, Action<string> error)
        {
            GetDataFunction<HeiztageDataItem[]>("/energymngmt/" +id +"/data", (Action<HeiztageDataItem[]>)success, (Action<string>)error);
        }
        public void EnergyManagementItemDataRange(int id, TimeRange range, Action<HeiztageDataItem[]> success, Action<string> error)
        {
            PostDataFunction<HeiztageDataItem[]>("/energymngmt/" + id + "/data", range, (Action<HeiztageDataItem[]>)success, (Action<string>)error);
        }
        public void EnergyManagementDataExportTxt(TrendSelection selection,  Action<string> success, Action<string> error)
        {
            GetTextPostFunction("/energymngmt/export/txt", selection, (Action<string>)success, (Action<string>)error);
        }
        public void EnergyManagementDataExportCsv(TrendSelection selection, Action<string> success, Action<string> error)
        {
            GetTextPostFunction("/energymngmt/export/csv", selection, (Action<string>)success, (Action<string>)error);
        }
        public void EnergyManagementFirstTime(Action<DateTime> success, Action<string> error)
        {
            GetDataFunction<DateTime>("/energymngmt/firsttime", (Action<DateTime>)success, (Action<string>)error);
        }
    }
}
