using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes.JSON
{
    public class TrendSettingsBuilder : IJSONObjectConverter
    {
        public bool CanCovert(Type type)
        {
            return type.Equals(typeof(RGAVerl.VisuPlus2000net.Models.TrendDatabaseSettings));
        }

        public class TrendDataBaseSettingsHelper : RGAVerl.VisuPlus2000net.Models.TrendDatabaseSettings
        {
            public override TrendSettingsProvider Provider { set;  get; }
        }

        public object GetObject(object jsonData, JSONBuilder jsonbuilder)
        {
            TrendDataBaseSettingsHelper helperObj = jsonbuilder.ConvertObject(jsonData, typeof(TrendDataBaseSettingsHelper))  as TrendDataBaseSettingsHelper;

            switch (helperObj.Provider)
            {
                case TrendSettingsProvider.None:
                    return jsonbuilder.ConvertObject(jsonData, typeof(TrendDatabaseSettingsNone));
                case TrendSettingsProvider.MsSql:
                    return jsonbuilder.ConvertObject(jsonData, typeof(TrendDatabaseSettingsMsSql));
            }
            return null;


        }
    }
}
