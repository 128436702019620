using Bridge;

namespace VisuPlusWebApp.Classes.Types
{
    public struct Size
    {
        public int Width { set; get; }
        public int Height { set; get; }

        public static Size FromWH(int width, int height)
        {
            return new Size()
            {
                Width = width,
                Height = height
            };
        }
    }
}