using System.Collections.Generic;
using System.Linq;

namespace RGAVerl.VisuPlus2000net.Models
{
    public class SystemTreeItem
    {
        public string Text { set; get; }

        //only relevant if last
        public int TreeProjectId { set; get; }
        public int PictureNumber { set; get; }
        public int SiteNumber { set; get; }
        public SessionSystemTreeTypes Type { set; get; }
        //last only
        public string Id {

            get
            {
                return "tree_" + TreeProjectId + "_site_" + SiteNumber + "_pic_" + PictureNumber;
            }
        }
        public IEnumerable<SystemTreeItem> SubItems { get; set; }

    }
}
