using System;
using System.ComponentModel;

namespace RGAVerl.VisuPlus2000net.Models
{
    public class CommunicationSymbolRegular : CommunicationSymbol
    {
        public string Address { set; get; }

        public override Models.CommunicationSymbolTypes SymbolType
        {
            get
            {
                return Models.CommunicationSymbolTypes.Regular;
            }
            set { }
        }

        public override string Information
        {
            get
            {
                return Address;
            }
            set { }
        }

    }
}
