using System;
using Bridge;
using Bridge.Html5;

namespace VisuPlusWebApp.Classes
{
    public class Settings
    {
        private const string ParamNameScaling = "windowscaling";
        private const string ParamNamePlaybing = "playbing";
        private const string ParamNameSessionKey = "sessionkey";

        private const string ParamNameFaultSortOrder = "faultsortorder";
        private const string ParamNameFaultSortType = "faultsorttype";

        private const string ParamNameWindowAfterLogoutId = "logoutwindowid";
        private const string ParamNameWindowAfterLogoutFullscreen = "logoutwindowfullscreen";

        private const string ParamNameStyle = "style";

        private const string ParamVersion = "appversion";

        public static string AppVersion
        {
            set
            {
                CheckInstance();
                _instance._appversion = value;
                _instance.SaveAppVersion();

            }
            get
            {
                CheckInstance();
                return _instance._appversion;
            }
        }


        public static int WindowScaling
        {
            set
            {
                CheckInstance();
                _instance._scaling = value;
                _instance.SaveScaling();
            }
            get
            {
                CheckInstance();
                return _instance._scaling;
            }
        }
        public static bool PlayBing
        {
            set
            {
                CheckInstance();
                _instance._playBing = value;
                _instance.SavePlayBing();
            }
            get
            {
                CheckInstance();
                return _instance._playBing;
            }
        }
        public static string SessionKey
        {
            set
            {
                CheckInstance();
                _instance._sessionKey = value;
                _instance.SaveSessionKey();
            }
            get
            {
                CheckInstance();
                return _instance._sessionKey;
            }
        }

        public static Enums.FaultListSortType FaultSortType
        {
            set
            {
                CheckInstance();
                _instance._faultSortType = value;
                _instance.SaveFaultSortType();
            }
            get
            {
                CheckInstance();
                return _instance._faultSortType;
            }
        }
        public static Enums.SortOrder FaultSortOrder
        {
            set
            {
                CheckInstance();
                _instance._faultSortOrder = value;
                _instance.SaveFaultSortOrder();
            }
            get
            {
                CheckInstance();
                return _instance._faultSortOrder;
            }
        }

        public static Enums.Theme Theme
        {
            set
            {
                CheckInstance();
                _instance._theme = value;
                _instance.SaveTheme();
            }
            get
            {
                CheckInstance();
                return _instance._theme;
            }
        }

        public static string WindowAfterLogoutId
        {
            set
            {
                CheckInstance();
                _instance._windowAfterLogoutId = value;
                _instance.SaveWindowAfterLogoutId();
            }
            get
            {
                CheckInstance();
                return _instance._windowAfterLogoutId;
            }
        }
        public static bool WindowAfterLogoutFullscreen
        {
            set
            {
                CheckInstance();
                _instance._windowAfterLogoutFullscreen = value;
                _instance.SaveWindowAfterLogoutFullscreen();
            }
            get
            {
                CheckInstance();
                return _instance._windowAfterLogoutFullscreen;
            }
        }


        private static Settings _instance = null;

        private int _scaling;
        private bool _playBing;
        private string _sessionKey;

        private Enums.FaultListSortType _faultSortType;
        private Enums.SortOrder _faultSortOrder;

        private string _windowAfterLogoutId;
        private bool _windowAfterLogoutFullscreen;

        private Enums.Theme _theme;
        private string _appversion;

        private Settings()
        {
            _scaling = 100;
            _playBing = true;
            _sessionKey = string.Empty;
            _theme = Enums.Theme.Dark;
        }

        private static void CheckInstance()
        {
            if (_instance == null)
            {
                _instance = new Settings();
                _instance.Load();
            }
        }

        private void Load()
        {
            var oScaling = Window.LocalStorage[ParamNameScaling];
            if (oScaling != null)
            {
                int.TryParse(oScaling.ToString(), out _scaling);
            }

            var oPlayBing = Window.LocalStorage[ParamNamePlaybing];
            if (oPlayBing != null)
            {
                bool.TryParse(oPlayBing.ToString(), out _playBing);
            }

            var oSessionKey = Window.LocalStorage[ParamNameSessionKey];
            if (oSessionKey != null)
            {
                _sessionKey = oSessionKey.ToString();
            }

            var oFaultSortOrtder = Window.LocalStorage[ParamNameFaultSortOrder];
            if (oFaultSortOrtder != null)
            {
                Enum.TryParse<Enums.SortOrder>(oFaultSortOrtder.ToString(), out _faultSortOrder);
            }

            var oFaultSortType = Window.LocalStorage[ParamNameFaultSortType];
            if (oFaultSortType != null)
            {
                Enum.TryParse<Enums.FaultListSortType>(oFaultSortType.ToString(), out _faultSortType);
            }

            var oStyle = Window.LocalStorage[ParamNameStyle];
            if (oStyle != null)
            {
                Enum.TryParse<Enums.Theme>(oStyle.ToString(), out _theme);
            }

            var oWindowAfterLogoutPath = Window.LocalStorage[ParamNameWindowAfterLogoutId];
            if (oWindowAfterLogoutPath != null)
            {
                _windowAfterLogoutId = oWindowAfterLogoutPath.ToString();
            }

            var oWindowAfterLogoutFullscreen = Window.LocalStorage[ParamNameWindowAfterLogoutFullscreen];
            if (oWindowAfterLogoutFullscreen != null)
            {
                bool.TryParse(oWindowAfterLogoutFullscreen.ToString(), out _windowAfterLogoutFullscreen);
            }

            var oAppVersion = Window.LocalStorage[ParamVersion];
            if (oAppVersion != null)
            {
                _appversion = oAppVersion.ToString();
            }


            UpdateTheme();
        }


        private void UpdateTheme()
        {
            var themecss = Document.GetElementById<HTMLLinkElement>("theme_stylesheet");
            var threethemecss = Document.GetElementById<HTMLLinkElement>("three_js_stylesheet");
            if (themecss == null) return;
            if (_theme == Enums.Theme.Light)
            {
                themecss.Href = Enums.LightThemeCss;
                threethemecss.Href = Enums.LightJsThreeCss;                
            }
            else if (_theme == Enums.Theme.Dark)
            {
                themecss.Href = Enums.DarkThemeCss;
                threethemecss.Href = Enums.DarkJsThreeCss;
            }
        }


        private void SaveScaling()
        {
            Window.LocalStorage[ParamNameScaling] = _scaling;
        }
        private void SavePlayBing()
        {
            Window.LocalStorage[ParamNamePlaybing] = _playBing;
        }
        private void SaveSessionKey()
        {
            Window.LocalStorage[ParamNameSessionKey] = _sessionKey;
        }
        private void SaveFaultSortOrder()
        {
            Window.LocalStorage[ParamNameFaultSortOrder] = _faultSortOrder;
        }
        private void SaveFaultSortType()
        {
            Window.LocalStorage[ParamNameFaultSortType] = _faultSortType;
        }
        private void SaveTheme()
        {
            Window.LocalStorage[ParamNameStyle] = _theme;

            UpdateTheme();
        }

        private void SaveWindowAfterLogoutId()
        {
            Window.LocalStorage[ParamNameWindowAfterLogoutId] = _windowAfterLogoutId;
        }
        private void SaveWindowAfterLogoutFullscreen()
        {
            Window.LocalStorage[ParamNameWindowAfterLogoutFullscreen] = _windowAfterLogoutFullscreen;
        }
        private void SaveAppVersion()
        {
            Window.LocalStorage[ParamVersion] = _appversion;
        }
    }
}
