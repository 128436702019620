using System;

namespace RGAVerl.VisuPlus2000net.Models
{
    //an active fault as object in the faultlist
    public class FaultListFault
    {
        public FaultId Id { set; get; }
        public DateTime Timecomes { set; get; }
        public DateTime? Timegoes { set; get; }
        public bool Quitted { set; get; }
        //public FaultDataItem DataFault { set; get; }
        
        public FaultListFault(FaultId id) 
        {
            Id = id;
            Timegoes = null;
        }
        public FaultListFault()
        {
        }
        public FaultListFault GetCopy()
        {
            return new FaultListFault()
            {
                Id = this.Id,
                Quitted = this.Quitted,
                Timecomes = this.Timecomes,
                Timegoes = this.Timegoes
            };
        }
    }
}
