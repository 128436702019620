using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Bridge.Html5;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes
{
    public partial class RestServerConnection : IServerConnection
    {
        public void TrendGetDatabases(Action<TrendDatabase[]> success, Action<string> error)
        {
            GetDataFunction<TrendDatabase[]>("/trend/databases", (Action<TrendDatabase[]>)success, (Action<string>)error);
        }

        public void TrendGetDataList(string databaseName, Action<TrendIndexItem[]> success, Action<string> error)
        {
            GetDataFunction<TrendIndexItem[]>("/trend/" + databaseName + "/list", (Action<TrendIndexItem[]>)success, (Action<string>)error);
        }

        public void TrendGetDataData(string databaseName, TrendSelection selection, Action<int> transferInfo, Action<TrendDataSeries[]> success, Action<string> error)
        {
            PostDataWithTransferFunction<TrendDataSeries[]>("/trend/" + databaseName + "/data",selection,(Action<TrendDataSeries[]>)success, (Action<int>)transferInfo, (Action<string>)error);
        }

        public void TrendGetDataExport(string databaseName, TrendSelection selection, Action<Blob> success, Action<string> error)
        {
            PostDataFunctionBlob("/trend/" + databaseName + "/export", selection, (Action<Blob>)success, (Action<string>)error);            
        }



        public void TrendDataIndexesAll(Action<TrendIndexItem[]> success, Action<string> error)
        {
            GetDataFunction<TrendIndexItem[]>("/trend/indexes", (Action<TrendIndexItem[]>)success, (Action<string>)error);
        }
        public void TrendDataIndexesGetById(int id, Action<TrendIndexItem> success, Action<string> error)
        {
            GetDataFunction<TrendIndexItem>("/trend/indexes/"+id, (Action<TrendIndexItem>)success, (Action<string>)error);
        }
        public void TrendDataIndexesAdd(TrendIndexItem index, Action<int> success, Action<string> error)
        {
            PostDataFunction<int>("/trend/indexes", index, (Action<int>)success, (Action<string>)error);
        }
        public void TrendDataIndexesEdit(int id, TrendIndexItem index, Action<bool> success, Action<string> error)
        {
            PutDataFunction<bool>("/trend/indexes/" +id, index,  (Action<bool>)success, (Action<string>)error);
        }
        public void TrendDataIndexesDelete(int id, Action<bool> success, Action<string> error)
        {
            DeleteDataFunction<bool>("/trend/indexes/" + id, (Action<bool>)success, (Action<string>)error);
        }


        public void TrendDataStationDescriptions(Action<string[]> success, Action<string> error)
        {
            GetDataFunction<string[]>("/trend/stationdescriptions", (Action<string[]>)success, (Action<string>)error);
        }
        public void TrendDataStationDeActivate(string stationDescriptionName, bool state, Action<bool> success, Action<string> error)
        {
            if (state)
            {
                PostDataFunction<bool>("/trend/activate", stationDescriptionName, (Action<bool>)success, (Action<string>)error);
            }
            else
            {
                PostDataFunction<bool>("/trend/deactivate", stationDescriptionName, (Action<bool>)success, (Action<string>)error);
            }
        }



        public void TrendUserSelectionll(Action<TrendUserSelection[]> success, Action<string> error)
        {
            GetDataFunction<TrendUserSelection[]>("/trend/selection", (Action<TrendUserSelection[]>)success, (Action<string>)error);
        }
        public void TrendUserSelectionGetById(int id, Action<TrendUserSelection> success, Action<string> error)
        {
            GetDataFunction<TrendUserSelection>("/trend/selection/" + id, (Action<TrendUserSelection>)success, (Action<string>)error);
        }

        public void TrendUserSelectionAdd(TrendUserSelection selection, Action<int> success, Action<string> error)
        {
            PostDataFunction<int>("/trend/selection", selection, (Action<int>)success, (Action<string>)error);
        }

        public void TrendUserSelectionEdit(int id, TrendUserSelection selection, Action<bool> success, Action<string> error)
        {
            PutDataFunction<bool>("/trend/selection/" +id, selection, (Action<bool>)success, (Action<string>)error);
        }

        public void TrendUserSelectionDelete(int id, Action<bool> success, Action<string> error)
        {
            DeleteDataFunction<bool>("/trend/selection/" + id, (Action<bool>)success, (Action<string>)error);
        }


        public void TrendUserSelectionItemsGet(int seriesId, Action<TrendUserSelectionItem[]> success, Action<string> error)
        {
            GetDataFunction<TrendUserSelectionItem[]>("/trend/selection/" +seriesId + "/items", (Action<TrendUserSelectionItem[]>)success, (Action<string>)error);
        }

        public void TrendUserSelectionItemsAdd(int seriesId, TrendUserSelectionItem[] items, Action<bool> success, Action<string> error)
        {
            PostDataFunction<bool>("/trend/selection/" + seriesId + "/items", items, (Action<bool>)success, (Action<string>)error);
        }

        public void TrendUserSelectionItemsDelete(int seriesId, int itemId, Action<bool> success, Action<string> error)
        {
            DeleteDataFunction<bool>("/trend/selection/" + seriesId + "/items/" +itemId, (Action<bool>)success, (Action<string>)error);
        }



    }
}
