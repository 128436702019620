using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes
{
    public partial class RestServerConnection : IServerConnection
	{
		public void ModbusSlaveAddressesGetAll(Action<ModbusSlaveAddress[]> success, Action<string> error)
        {
			GetDataFunction<ModbusSlaveAddress[]>("/modbusslave/addresses", (Action<ModbusSlaveAddress[]>)success, (Action<string>)error);
        }

		public void ModbusSlaveAddressesGetById(int id, Action<ModbusSlaveAddress> success, Action<string> error)
        {
			GetDataFunction<ModbusSlaveAddress>("/modbusslave/addresses/+"+id, (Action<ModbusSlaveAddress>)success, (Action<string>)error);
        }

		public void ModbusSlaveAddressesAdd(ModbusSlaveAddress stationSettings, Action<int> success, Action<string> error)
        {
			PostDataFunction<int>("/modbusslave/addresses", stationSettings, (Action<int>)success, (Action<string>)error);
        }

		public void ModbusSlaveAddressesEdit(int id, ModbusSlaveAddress stationSettings, Action<bool> success, Action<string> error)
        {
			PutDataFunction<bool>("/modbusslave/addresses/" +id, stationSettings, (Action<bool>)success, (Action<string>)error);
        }

		public void ModbusSlaveAddressesDelete(int id, Action<bool> success, Action<string> error)
        {
			DeleteDataFunction<bool>("/modbusslave/addresses/" +id, (Action<bool>)success, (Action<string>)error);
        }

    }
}
