using System;
using System.ComponentModel.DataAnnotations;

namespace RGAVerl.VisuPlus2000net.Models
{
    /// <summary>
    /// in index with all information
    /// </summary>
    public class TrendIndexItem
    {
        public int Id { set; get; }

        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string StationDescription { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string DataDescription { set; get; }

        public float Interval { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string StationName { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string DataAddress { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string DataFormatting { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string DataOption { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Unit { set; get; }
        public bool Active { set; get; }
        public DateTime ReadNext {
            get { return ReadLast.AddMinutes(Interval); }
        } 
        public DateTime ReadFirst { set; get; }
        public DateTime ReadLast { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string DataTableName { set; get; }
        
        public TrendIndexItem() {
            this.StationDescription = String.Empty;
            this.DataDescription = String.Empty;
            this.StationName = String.Empty;
            this.DataAddress = String.Empty;
            this.DataFormatting = String.Empty;
            this.DataOption = String.Empty;
            this.Unit = String.Empty;
            this.DataTableName = String.Empty;

        } 

        public override string ToString()
        {
            return "ID: " + Id + " Table: " + DataTableName;
        }
    }
}
