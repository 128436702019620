using System.ComponentModel.DataAnnotations;

namespace RGAVerl.VisuPlus2000net.Models
{
    public class StationSettingsModbus : StationSettings
    {
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string SourceStation { set; get; }

        public override StationProvider StationType
        {
            set {  }
            get
            {
                return StationProvider.Modbus;
            }
        }

        public override string ProviderInfo
        {
            set { }
            get { return "Quellstation: " + SourceStation; }
        }
    }
}
