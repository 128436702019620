using System.ComponentModel.DataAnnotations;

namespace RGAVerl.VisuPlus2000net.Models
{
    public class NotificationServiceHiLink : NotificationService
    {
        public override NotificationServiceProvider ServiceType
        {
            set { }
            get
            {
                return NotificationServiceProvider.HiLink;
            }
        }


        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string UrlAddress { set; get; }
        public bool LoginRequired { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string LoginName { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string LoginPassword { set; get; }


        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Text { set; get; }
    }
}
