using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace RGAVerl.VisuPlus2000net.Models
{
    /// <summary>
    /// system tree project 
    /// </summary>
    public class DbSystemTreeElementProject : DbSystemTreeElement
    {
        public string Description { set; get; }
        public string Path { set; get; }
        public string SymbolPath { set; get; }

        public override SystemTreeItemTypes TreeItemType
        {
            set { }
            get { return SystemTreeItemTypes.FileProject; }
        }
    }
}
