using System.ComponentModel.DataAnnotations;

namespace RGAVerl.VisuPlus2000net.Models
{
    public class StationSettingsSaia : StationSettings
    {
        public StationSaiaConnectionTypes ConnectionType { set; get; }
        public int SbusStation { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Address { set; get; }
        public int NetworkPort { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string SerialPort { set; get; }
        public int Ts_Delay { set; get; }
        public int Tn_Delay { set; get; }
        public int Baudrate { set; get; }

        public override StationProvider StationType
        {
            set { }
            get
            {
                return StationProvider.Saia;
            }
        }

        public override string ProviderInfo
        {
            set { }
            get { return "Sbus-Station: " + SbusStation + " Typ:" + ConnectionType.ToString(); }
        }
    }
}
