
namespace RGAVerl.VisuPlus2000net.Models
{
    public class CommunicationSymbolModbusDouble : CommunicationSymbol
    {
        public string RegisterOne { set; get; }
        public string RegisterTwo { set; get; }
        public string RegisterThree { set; get; }
        public string RegisterFour { set; get; }
        public int Divisor { set; get; }
        public int DecimalPoints { set; get; }

        public override Models.CommunicationSymbolTypes SymbolType
        {
            get
            {
                return Models.CommunicationSymbolTypes.ModbusDouble;
            }
            set { }
        }

        public override string Information
        {
            get
            {
                return "Reg1: " + RegisterOne + " Reg2: " + RegisterTwo + " Reg3: " + RegisterThree + " Reg4: " + RegisterFour + " Divisor: "  + Divisor;
            }
            set { }
        }
        
    }
}
