using System;
using System.Collections.Generic;
using System.Threading.Tasks;
using Bridge;
using Bridge.Html5;
using RGAVerl.VisuPlus2000net.Models;
using System.Linq;


namespace VisuPlusWebApp.Classes
{
    public class FaultList
    {
        private const int PollInterval = 100;

        //update event
        public class FaultListUpdate
        {
            public RGAVerl.VisuPlus2000net.Models.FaultTypes State { set; get; }
            public SessionUserFault Fault { set; get; }
        }
        //events
        public Action UpdateAll;
        public System.Action<FaultListUpdate> Updated;
        public System.Action UpdateError;

        //inner list
        private Dictionary<string, SessionUserFault> _innerFaultList = new Dictionary<string, SessionUserFault>();
                
        //audio notification
        private HTMLAudioElement _binger;

        private IServerConnection _serverConnection;
        private int _updateCounter = -1;
        
                
        public FaultList(IServerConnection serverConnection)
        {
            _serverConnection = serverConnection;

            //add audio element
            _binger = new HTMLAudioElement()
            {
                AutoPlay = false,
                Src = "/audio/ping.wav",
                Loop = true
            };
            //general stop for audio
            Window.OnMouseDown += @event =>
            {
                _binger.Pause();
            };
        }

        /// <summary>
        /// list has any faults
        /// </summary>
        public bool HasFaults()
        {
            return System.Linq.Enumerable.Any<KeyValuePair<string,SessionUserFault>>(_innerFaultList);
        }
        public int GetFaultCount()
        {
            return _innerFaultList.Count;
        }
        public int GetQuittedFaults()
        {
            return System.Linq.Enumerable.Where<KeyValuePair<string,SessionUserFault>>(_innerFaultList,(Func<KeyValuePair<string,SessionUserFault>,bool>)(item => item.Value.ListItem.Quitted)).Count();
        }

        /// <summary>
        /// retuns the sorted list
        /// </summary>
        /// <returns></returns>
        public SessionUserFault[] GetSortedList()
        {
            SessionUserFault[] sortedList = System.Linq.Enumerable.ToArray<SessionUserFault>(_innerFaultList.Values);

            sortedList.JsSort((Func<object,object,int>)CompareFunction);

            return sortedList;
        }

        private int CompareFunction(object o, object o1)
        {
            SessionUserFault fo = o  as SessionUserFault;
            SessionUserFault fo1 = o1 as SessionUserFault;

            int result = 0;

            if (Settings.FaultSortType == Enums.FaultListSortType.Text)
            {
                result= fo.Text.CompareTo(fo1.Text);
            }
            else if (Settings.FaultSortType == Enums.FaultListSortType.Goes)
            {
                if (fo.ListItem.Timegoes.HasValue && fo1.ListItem.Timegoes.HasValue)
                {
                    result = fo.ListItem.Timegoes.Value.CompareTo(fo1.ListItem.Timegoes.Value);
                }
                else if(fo.ListItem.Timegoes.HasValue)
                {
                    result = -1;
                }
                result = 1;
            }
            else if (Settings.FaultSortType == Enums.FaultListSortType.Quitted)
            {
                result = fo.ListItem.Quitted.CompareTo(fo1.ListItem.Quitted);
            }
            else
            {
                result = fo.ListItem.Timecomes.Ticks.CompareTo(fo1.ListItem.Timecomes.Ticks);
            }

            if (Settings.FaultSortOrder == Enums.SortOrder.DESC)
            {
                result = result*-1;
            }
            return result;
        }        

        public void QuitFault(FaultId id)
        {
            _serverConnection.FaultListQuit(id, (Action<bool>)((b) => { }), (Action<string>)((s) => { }));
        }
        public void DeleteFault(FaultId id)
        {
            _serverConnection.FaultListDelete(id, (Action<bool>)((b) => { }), (Action<string>)((s) => { }));
        }


        //polling routine
        private void DoPollFaults()
        {
            Window.SetTimeout((Action)GetFaultUpdate, PollInterval);
        }
        //funtion for polling
        private void GetFaultUpdate()
        {
            _serverConnection.FaultListGetUpdate(_updateCounter, (Action<SessionFaultUpdate>)UpdateFaultList, (Action<string>)Error);
        }
        
        
        private void UpdateFaultList(RGAVerl.VisuPlus2000net.Models.SessionFaultUpdate update)
        {
            try
            {
                //should update complete list
                if (update.UpdateAll )
                {
                    //clear inner list
                    _innerFaultList.Clear();
                    //get all faults from server
                    _serverConnection.FaultListGetAllForUser(
(Action<SessionUserFault[]>)(                        success =>
                        {
                            foreach (var sessionUserFault in success)
                            {
                                _innerFaultList.Add(sessionUserFault.ListItem.Id.ToString(), sessionUserFault);
                            }
                            DoPollFaults();
                            if (UpdateAll != null) UpdateAll();
                        }), 
(Action<string>)(                        error =>
                        {
                            throw new Exception(error);
                        }
)                    );
                    _updateCounter = update.PpCount+ 1;
                }
                //something went wrong  
                else if (_updateCounter != update.PpCount)
                {
                    throw new Exception("poll count is different");
                }
                else
                {
                    //if update has any faults
                    if (update.Faults != null)
                    {
                        foreach (SessionFaultUpdateFault fault in update.Faults)
                        {
                            SessionUserFault faultInList = null;

                            //try find item in list
                            if (_innerFaultList.TryGetValue(fault.Fault.ListItem.Id.ToString(), out faultInList))
                            {
                                //update exsisting
                                UpdateFault(faultInList, fault.Fault);
                            }
                            else if(fault.FaultType == FaultTypes.Comes)
                            {
                                //add new
                                faultInList = fault.Fault;
                                _innerFaultList.Add(fault.Fault.ListItem.Id.ToString(), fault.Fault);
                            }
                            else
                            {
                                //ignore
                                continue;
                            }

                            //remove from list
                            if (fault.FaultType == FaultTypes.Deleted)
                            {
                                _innerFaultList.Remove(fault.Fault.ListItem.Id.ToString());
                            }

                            if (this.Updated !=null)
                            {
                                //send update event
                                this.Updated(new FaultListUpdate()
                                {
                                    Fault = faultInList,
                                    State = fault.FaultType,
                                    
                                });
                            }
                            if (fault.FaultType == FaultTypes.Comes && Settings.PlayBing)
                            {
                                _binger.Play();
                            }
                        }
                    }
                    _updateCounter ++;
                    DoPollFaults();
                }
            }
            catch (Exception ex)
            {
                Error(ex.ToString());
            }
        }

        public void TestSound()
        {
            if(Settings.PlayBing)
            {
                _binger.Play();
            }
        }
        

        private void UpdateFault(SessionUserFault originalFault, SessionUserFault updatedFault)
        {
            //found
            //update all parameter
            originalFault.Text = updatedFault.Text;
            originalFault.Color = updatedFault.Color;
            originalFault.InfoText = updatedFault.InfoText;
            originalFault.ListItem.Timegoes = updatedFault.ListItem.Timegoes;
            originalFault.ListItem.Quitted = updatedFault.ListItem.Quitted;
            originalFault.ListItem.Timecomes = updatedFault.ListItem.Timecomes;
        }
        
        


        private void Error(string message)
        {

           // Console.WriteLine(message);

            if(UpdateError != null) UpdateError();
            _updateCounter = -2;
            Window.SetTimeout((Action)DoPollFaults, PollInterval *10);
        }

      
        public void StartUpdate()
        {
            _updateCounter = -2;
            DoPollFaults();
        }

    }
}
