using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes
{
    public partial class RestServerConnection : IServerConnection
    {

        public void FaultHistoryGetAll(Action<FaultHistoryItem[]> success, Action<string> error)
        {
            GetDataFunction<FaultHistoryItem[]>("/faulthistory", (Action<FaultHistoryItem[]>)success, (Action<string>)error);
        }

        public void FaultHistoryGetRange(DateTime start, DateTime end, Action<FaultHistoryItem[]> success, Action<string> error)
        {
            var range = new TimeRange()
            {
                End = end,
                Start = start
            };

            PostDataFunction<FaultHistoryItem[]>("/faulthistory/range", range, (Action<FaultHistoryItem[]>)success, (Action<string>)error);
        }

        public void FaultHistorySearch(string searchText, Action<FaultHistoryItem[]> success, Action<string> error)
        {
            PostDataFunction<FaultHistoryItem[]>("/faulthistory/search", searchText, (Action<FaultHistoryItem[]>)success, (Action<string>)error);
        }

        public void FaultHistoryById(FaultId faultid, Action<FaultHistoryItem[]> success, Action<string> error)
        {
            PostDataFunction<FaultHistoryItem[]>("/faulthistory/search", faultid, (Action<FaultHistoryItem[]>)success, (Action<string>)error);
        }

        public void FaultHistoryClearRange(DateTime start, DateTime end, Action<bool> success, Action<string> error)
        {
            var range = new TimeRange()
            {
                End = end,
                Start = start
            };

            PostDataFunction<bool>("/faulthistory/clearrange", range, (Action<bool>)success, (Action<string>)error);
        }

        public void FaultHistoryClearAll(Action<bool> success, Action<string> error)
        {
            GetDataFunction<bool>("/faulthistory/clearall", (Action<bool>)success, (Action<string>)error);
        }
    }
}
