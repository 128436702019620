using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes
{
    public partial class RestServerConnection : IServerConnection
    {
        public void VisuPlusGetRandom(Action<string> success, Action<string> error)
        {
            GetTextFunction("/random", (Action<string>)success, (Action<string>)error);
        }

        public void VisuPlusGetProjectImage(Action<object> success, Action<string> error)
        {
            success("/api/projectimage");
        }

        public void VisuPlusGetProjectInfo(Action<ProjectInfo> success, Action<string> error)
        {
            GetDataFunction<ProjectInfo>("/projectinfos", (Action<ProjectInfo>)success, (Action<string>)error);
        }

        public void VisuPlusSendHeartbeat(Action<DateTime> success, Action<string> error)
        {
            GetDataFunction<DateTime>("/heartbeat", (Action<DateTime>)success, (Action<string>)error);
        }

    }
}
