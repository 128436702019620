using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes
{
    public partial class RestServerConnection : IServerConnection
    {

        public void SystemPictureGetWindow(string windowId, Action<ControlPictureStructure> success, Action<string> error)
        {
            GetDataFunction<ControlPictureStructure>("/controlpicture/" + windowId + "/structure", (Action<ControlPictureStructure>)success, (Action<string>)error);
        }

        public void SystemPictureGetMiniWindow(string windowId, int elementId, Action<ControlPictureStructure> success, Action<string> error)
        {
            GetDataFunction<ControlPictureStructure>("/controlpicture/" + windowId + "/miniwindow/" + elementId, (Action<ControlPictureStructure>)success, (Action<string>)error);
        }

        public void SystemPictureGetBackImage(string windowId, Action<string> success, Action<string> error)
        {
            success(string.Format("/api/helper/image/{0}/{1}/{2}/{3}/{4}", _apiKey, "syspicback", windowId, "empty",
                "empty"));
        }

        public void SystemPictureGetVarUpdate(string windowId, bool full, Action<ControlPictureUpdate> success, Action<string> error)
        {
            if (full)
            {
                GetDataFunction<ControlPictureUpdate>("/controlpicture/" + windowId + "/update/full", (Action<ControlPictureUpdate>)success, (Action<string>)error);
            }
            else
            {
                GetDataFunction<ControlPictureUpdate>("/controlpicture/" + windowId + "/update", (Action<ControlPictureUpdate>)success, (Action<string>)error);
            }
        }

        public void SystemPictureGetVarImage(string windowId, int elementId, string value, Action<string> success, Action<string> error)
        {
            success(string.Format("/api/helper/image/{0}/{1}/{2}/{3}/{4}", _apiKey, "syspicvar", windowId, elementId,
                value));
        }

        public void SystemPictureGetValueInfo(string windowId, int elementId, Action<ControlPictureChangeValueInfo> success, Action<string> error)
        {
            GetDataFunction<ControlPictureChangeValueInfo>("/controlpicture/" + windowId + "/varinfo/" + elementId, (Action<ControlPictureChangeValueInfo>)success, (Action<string>)error);
        }

        public void SystemPictureSetValue(string windowId, int elementId, string value, Action<bool> success, Action<string> error)
        {
            PostDataFunction<bool>("/controlpicture/" + windowId + "/varchange/" + elementId, value, (Action<bool>)success, (Action<string>)error);
        }
    }
}
