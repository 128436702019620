using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes.JSON
{
    public class StationSettingsBuilder : IJSONObjectConverter
    {
        public bool CanCovert(Type type)
        {
            return type.Equals(typeof(RGAVerl.VisuPlus2000net.Models.StationSettings));
        }

        public class StationSettingsBaseHelper : RGAVerl.VisuPlus2000net.Models.StationSettings
        {
            public override StationProvider StationType { set; get; }          

            public override string ProviderInfo { set; get; }
        }

        public object GetObject(object jsonData, JSONBuilder jsonbuilder)
        {
            StationSettingsBaseHelper helperObj = jsonbuilder.ConvertObject(jsonData, typeof(StationSettingsBaseHelper))  as StationSettingsBaseHelper;

            switch (helperObj.StationType)
            {
                case StationProvider.Saia:
                    return jsonbuilder.ConvertObject(jsonData, typeof(StationSettingsSaia));
                case StationProvider.Beckhoff:
                    return jsonbuilder.ConvertObject(jsonData, typeof(StationSettingsBeckhoff));
                case StationProvider.Modbus:
                    return jsonbuilder.ConvertObject(jsonData, typeof(StationSettingsModbus));
                case StationProvider.BacnetAds:
                    return jsonbuilder.ConvertObject(jsonData, typeof(StationSettingsBacnetAds));
                case StationProvider.Ads:
                    return null;
                case StationProvider.Bacnet:
                    return null;
            }
            return null;


        }
    }
}
