using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Text.RegularExpressions;
using System.Threading.Tasks;
using Bridge;

namespace VisuPlusWebApp.Classes.JSON
{
    public class JSONBuilder
    {
        public delegate object DateTimeReviverDelegate(string key, object value);

        private const string DateTimeRegEx = @"(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)";
        private DateTimeReviverDelegate _reviver;
        private Regex _dateRegEx;

        private List<IJSONObjectConverter> _additionalConverter;

        public JSONBuilder(IEnumerable<IJSONObjectConverter> additionalConverter =  null)
        {
            _dateRegEx = new Regex(DateTimeRegEx);
            _reviver = new DateTimeReviverDelegate(DateTimeReviver);

            _additionalConverter = new List<IJSONObjectConverter>();
            if(additionalConverter != null)
            {
                _additionalConverter.AddRange(additionalConverter);
            }
        }

        private object DateTimeReviver(string key, object value)
        {

            if (value is string && _dateRegEx.IsMatch(value.ToString()))
            {

                DateTime dateValue;

                if (DateTime.TryParse(value.ToString(), out dateValue))
                {
                    return dateValue;
                }
            }

            return value;
        }


        public object ConvertObject(object jsonObject, Type outType)
        {
            //Console.WriteLine(outType.ToString() + " is " + (outType == typeof(Enumerable)));
            //if excplicit nullable create object or null
            Type nullableType = Nullable.GetUnderlyingType(outType);            
            if (nullableType != null)
            {
                if (jsonObject == null) return null;
                return ConvertObject(jsonObject, nullableType);
            }
            //null create base type
            if (jsonObject == null)
            {
                if(typeof(System.Collections.IEnumerable).IsAssignableFrom(outType))
                {
                    return Activator.CreateInstance(typeof(List<>).MakeGenericType(new [] { outType}));
                }
                else if (typeof(System.Collections.IList).IsAssignableFrom(outType))
                {
                    return Activator.CreateInstance(typeof(List<>).MakeGenericType(new[] { outType }));
                }
                return Activator.CreateInstance(outType);
            }
            else if(outType == typeof(DateTime))
            {
                return DateTime.Parse(jsonObject.ToString());
            }
            else if(outType == typeof(TimeSpan))
            {
                return TimeSpan.Parse(jsonObject.ToString());
            }
            //convert primitive types to object (bridge would create classes instead)
            else if ((outType == typeof(object) && jsonObject.GetType() == typeof(string)) ||
                (outType == typeof(object) && !jsonObject.GetType().IsClass && !jsonObject.GetType().IsArray))
            {
                return jsonObject;
            }
            //first array -> array would be object 
            //if json object is array this should be an enum
            else if (outType.IsArray|| jsonObject.GetType().IsArray)
            {
                Type elementType;
                if (outType.IsArray)
                {
                    elementType = outType.GetElementType();
                }
                else
                {
                    elementType = outType.GetGenericArguments()[0];
                }
                var obArray = Array.CreateInstance(elementType, (jsonObject as Array).Length);
                for (int i = 0; i < (jsonObject as Array).Length; i++)
                {
                    obArray[i] = ConvertObject((jsonObject as Array)[i], elementType);
                }
                return obArray;
            }
            //any different type of ienumerable
            //else if ()
            //{
            //    var obArray = Array.CreateInstance(, (jsonObject as Array).Length);

            //    for (int i = 0; i < (jsonObject as Array).Length; i++)
            //    {
            //        obArray[i] = ConvertObject((jsonObject as Array)[i], outType.GetGenericArguments()[0]);
            //    }
            //    return obArray;
            //}
            //extra string -> string is class
            else if (outType == typeof(string))
            {
                return jsonObject;
            }
            //class convert here
            else if (outType.IsClass)
            {
                //use special converter
                var addConv = System.Linq.Enumerable.Where<IJSONObjectConverter>(_additionalConverter,(Func<IJSONObjectConverter,bool>)(conv => conv.CanCovert(outType))).FirstOrDefault();
                if (addConv != null)
                {
                    return addConv.GetObject(jsonObject, this);
                }
                //basic class convert
                else
                {
                    //create base object
                    object outObj = Activator.CreateInstance(outType);
                    //create dynamic json object and get properties
                    var dynJsonObject = jsonObject.ToDynamic();
                    string[] jsonProps = object.GetOwnPropertyNames(jsonObject);
                    //get properties of out object
                    var properties = outType.GetProperties();

                    //loop through all props
                    foreach (var property in properties)
                    {
                        if (!property.CanWrite) continue;
                        //find matching property in json object
                        var jsonPropName = System.Linq.Enumerable.Where<string>(jsonProps,(Func<string,bool>)(name => name.Equals(property.Name, StringComparison.CurrentCultureIgnoreCase))).FirstOrDefault();
                        
                        //create default object insteat of null --- todo make settable
                        if (jsonPropName == null)
                        {
                            property.SetValue(outObj, Activator.CreateInstance(property.PropertyType));
                        }
                        //recursive call for property
                        else
                        {
                            property.SetValue(outObj, ConvertObject(dynJsonObject[jsonPropName], property.PropertyType));
                        }
                    }
                    return outObj;
                }
            }
            //primitive type
            else
            {
                return jsonObject;
            }
        }

    }
}
