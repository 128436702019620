using System.ComponentModel.DataAnnotations;

namespace RGAVerl.VisuPlus2000net.Models
{
    public class NotificationServiceGSM : NotificationService
    {
        public override NotificationServiceProvider ServiceType
        {
            set { }
            get
            {
                return NotificationServiceProvider.GSM;
            }
        }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Port { set; get; }
        public int Baud { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Pin { set; get; }

        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Text { set; get; }
    }
}
