using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes.JSON
{
    public class NotificationServiceBuilder : IJSONObjectConverter
    {
        public bool CanCovert(Type type)
        {
            return type.Equals(typeof(RGAVerl.VisuPlus2000net.Models.NotificationService));
        }

        public class NotificationServiceHelper : RGAVerl.VisuPlus2000net.Models.NotificationService
        {
            public override NotificationServiceProvider ServiceType { set; get; }

        }

        public object GetObject(object jsonData, JSONBuilder jsonbuilder)
        {
            NotificationServiceHelper helperObj = jsonbuilder.ConvertObject(jsonData, typeof(NotificationServiceHelper))  as NotificationServiceHelper;

            switch (helperObj.ServiceType)
            {
                case NotificationServiceProvider.GSM:
                    return jsonbuilder.ConvertObject(jsonData, typeof(NotificationServiceGSM));
                case NotificationServiceProvider.Mail:
                    return jsonbuilder.ConvertObject(jsonData, typeof(NotificationServiceMail));
                case NotificationServiceProvider.Output:
                    return jsonbuilder.ConvertObject(jsonData, typeof(NotificationServiceOutput));
                case NotificationServiceProvider.HiLink:
                    return jsonbuilder.ConvertObject(jsonData, typeof(NotificationServiceHiLink));

            }
            return null;


        }
    }
}
