using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes
{
    public partial class RestServerConnection : IServerConnection
    {

        public void UserGetForLogin(Action<User[]> success, Action<string> error)
        {
            GetDataFunction<User[]>("/session/users", (Action<User[]>)success, (Action<string>)error);
        }

        public void UserLogin(string userName, string password, bool stayLoggedIn, Action<bool> success, Action<string> error)
        {
            PostDataFunction<bool>("/session/login", new LoginCredential() {Password =  password, Username = userName }, (Action<bool>)success, (Action<string>)error );
        }

        public void UserLogout(Action<bool> success, Action<string> error)
        {
            GetDataFunction<bool>("/session/logout", (Action<bool>)success, (Action<string>)error);
        }

        public void UserIsLoggedIn(Action<bool> success, Action<string> error)
        {
            GetDataFunction<bool>("/session/isloggedin", (Action<bool>)success, (Action<string>)error);
        }

        public void UserLoggedInUser(Action<User> success, Action<string> error)
        {
            GetDataFunction<User>("/session/loggedinuser", (Action<User>)success, (Action<string>)error);
        }

        public void UserLoadLogin(string key, Action<bool> success, Action<string> error)
        {
            PostDataFunction<bool>("/session/loadlogin", key ,(Action<bool>)success, (Action<string>)error);
        }

        public void UserSaveLogin(Action<string> success, Action<string> error)
        {
            GetDataFunction<string>("/session/savelogin", (Action<string>)success, (Action<string>)error);
        }

    }
}
