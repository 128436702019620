using System;
using Bridge;
using Bridge.Html5;
using RGAVerl.VisuPlus2000net.Models;


namespace VisuPlusWebApp.Classes
{
    public static class Functions
    {
        public static Types.Point GetMousePosition(HTMLDivElement element, Event e)
        {
            var rect = (ClientRect) element.GetBoundingClientRect();
            var mouse = (MouseEvent)e;
            var px = (int)(mouse.ClientX - rect.Left);
            var py = (int)(mouse.ClientY - rect.Top);

            return new Classes.Types.Point(px, py);
        }

     
        public static HTMLOptionElement GetZeroOption(bool selected= true)
        {
            var option = new HTMLOptionElement()
            {
                TextContent = "",
                Value = "",
                Disable = true,
                Selected = selected
            };
            option.Style.Display = Display.None;
            return option;
        }

        public static string DatetimeToLocalDateTimeString(DateTime time)
        {
            return time.ToString("dd.MM.yyyy HH:mm:ss");
        }
        public static string DatetimeToLocalDateString(DateTime time)
        {
            return time.ToString("dd.MM.yyyy");
        }

        public static string DatetimeToLocalDateTimeString(DateTime? time)
        {
            if (time.HasValue)
            {
                return time.Value.ToString("dd.MM.yyyy HH:mm:ss");
            }
            return "";
        }
        public static string DatetimeToLocalDateString(DateTime? time)
        {
            if (time.HasValue)
            {
                return time.Value.ToString("dd.MM.yyyy");
            }
            return "";
        }

        public static string TimespanToString(TimeSpan span)
        {
            string retStr = "";
            if (span.TotalDays >= 1) retStr += (int)span.TotalDays + " Tage ";
            if (span.Hours >= 1) retStr += (int)span.Hours + " Stunden ";
            if (span.Minutes >= 1) retStr += (int)span.Minutes + " Minuten ";
            if (span.Seconds >= 1) retStr += (int)span.Seconds + " Sekunden ";
            return retStr;
        }

        //public static string GetRandomColor()
        //{
        //    Random r = new Random();
        //    int nbr = r.Next(23);
        //    string col = HslToRgb((nbr * 15), 1, 1);

        //    Console.WriteLine("Calculated Color :" + col + " With R: " +nbr);
        //    return col;
        //}

        public  static string HslToRgb(double hue, double saturation, double value)
        {

            int hi = Convert.ToInt32(Math.Floor(hue / 60)) % 6;
            double f = hue / 60 - Math.Floor(hue / 60);

            value = value * 255;
            byte v = Convert.ToByte(value);
            byte p = Convert.ToByte(value * (1 - saturation));
            byte q = Convert.ToByte(value * (1 - f * saturation));
            byte t = Convert.ToByte(value * (1 - (1 - f) * saturation));

            if (hi == 0)
                return HexColFromRGB( v, t, p);
            else if (hi == 1)
                return HexColFromRGB( q, v, p);
            else if (hi == 2)
                return HexColFromRGB( p, v, t);
            else if (hi == 3)
                return HexColFromRGB( p, q, v);
            else if (hi == 4)
                return HexColFromRGB( t, p, v);
            else
                return HexColFromRGB( v, p, q);




            //double red = 0;
            //double green = 0;
            //double blue = 0;
            //if (s == 0)
            //{
            //    red = l;
            //    green = l;
            //    blue = l;
            //}
            //else
            //{

            //    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            //    var p = 2 * l - q;
            //    red = HueToRGB(p, q, h + 1 / 3);
            //    green = HueToRGB(p, q, h);
            //    blue = HueToRGB(p, q, h - 1 / 3);

            //}
            //Console.WriteLine("R: " + red + " Green: " + green + " Blue:" + blue);
            //string sRed = Math.Round((red*255),0).ToString(16);

            //string sGreen = Math.Round((green * 255), 0).ToString(16);
            //string sBlue = Math.Round((blue * 255), 0).ToString(16);

            //return "#" + sRed + sGreen + sBlue;

        }
        
        public static bool HasUserRight(User user,  UserRights right)
        {
            return ((UserRights)user.Rights).HasFlag(UserRights.IsAdmin) ||
                   ((UserRights)user.Rights).HasFlag(right);
        }
        private static string HexColFromRGB(byte red, byte green, byte blue)
        {
            string sRed = red.ToString("X2");

            string sGreen =green.ToString("X2");
            string sBlue = blue.ToString("X2");

            return "#" +  sRed +   sGreen +  sBlue;

        }


        public static void CenterWindow(HTMLDivElement window)
        {
            var htmlObject = window;
            int parentWidth = htmlObject.ParentElement.ClientWidth;
            int parentHeight = htmlObject.ParentElement.ClientHeight;
            int posx = ((parentWidth / 2) - (htmlObject.ClientWidth / 2));
            if(posx < 0)
            {
                posx = 0;
            }
            int posy = ((parentHeight / 2) - (htmlObject.ClientHeight / 2));
            if (posy < 0)
            {
                posy = 0;
            }


            htmlObject.Style.SetProperty("left",  posx+ "px");
            htmlObject.Style.SetProperty("top",  posy+ "px");
        }


        //private static double HueToRGB(double p, double q, double t)
        //{
        //    if (t < 0) t += 1;
        //    if (t > 1) t -= 1;
        //    if (t < 1.0 / 6.0) return p + (q - p) * 6.0 * t;
        //    if (t < 1.0 / 2.0) return q;
        //    if (t < 2.0 / 3.0) return p + (q - p) * (2.0 / 3.0 - t) * 6.0;
        //    return p;
        //}
    }
}
