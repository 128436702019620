using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes
{
    public partial class RestServerConnection : IServerConnection
	{
		public void DatatransfersGetAll(Action<DatatransferAddress[]> success, Action<string> error)
        {
			GetDataFunction<DatatransferAddress[]>("/datatransfers", (Action<DatatransferAddress[]>)success, (Action<string>)error);
        }

		public void DatatransfersGetById(int id, Action<DatatransferAddress> success, Action<string> error)
        {
			GetDataFunction<DatatransferAddress>("/datatransfers/"+id, (Action<DatatransferAddress>)success, (Action<string>)error);
        }

		public void DatatransfersAdd(DatatransferAddress stationSettings, Action<int> success, Action<string> error)
        {
			PostDataFunction<int>("/datatransfers", stationSettings, (Action<int>)success, (Action<string>)error);
        }

		public void DatatransfersEdit(int id, DatatransferAddress stationSettings, Action<bool> success, Action<string> error)
        {
			PutDataFunction<bool>("/datatransfers/" +id, stationSettings, (Action<bool>)success, (Action<string>)error);
        }

		public void DatatransfersDelete(int id, Action<bool> success, Action<string> error)
        {
			DeleteDataFunction<bool>("/datatransfers/" +id, (Action<bool>)success, (Action<string>)error);
		}


    }
}
