using System;
using System.Collections.Generic;

using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes
{
	public partial class RestServerConnection : IServerConnection
	{

		public void LogsGetFiles(Action<LogFileInfo[]> success, Action<string> error)
		{
			GetDataFunction<LogFileInfo[]>("/logs", (Action<LogFileInfo[]>)success, (Action<string>)error);
		}

		public void LogsGetFileData(string file, Action<string> success, Action<int> transferInfo, Action<string> error)
		{
			GetTextFunction("/logs/" + file, (Action<string>)success, (Action<string>)error, (Action<int>)transferInfo);
		}
	}
}