using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes
{
    public partial class RestServerConnection : IServerConnection
    {
        public void NotificationServiceGetAllNames(Action<NotificationService[]> success, Action<string> error)
        {
            GetDataFunction<NotificationService[]>("/notifctnservice/names", (Action<NotificationService[]>)success, (Action<string>)error);
        }

        public void NotificationServiceGetAll(Action<NotificationService[]> success, Action<string> error)
        {
            GetDataFunction<NotificationService[]>("/notifctnservice", (Action<NotificationService[]>)success, (Action<string>)error);
        }

        public void NotificationServiceGetById(int id, Action<NotificationService> success, Action<string> error)
        {
            GetDataFunction<NotificationService>("/notifctnservice/" + id, (Action<NotificationService>)success, (Action<string>)error);
        }

        public void NotificationServiceAdd(NotificationService service, Action<int> success, Action<string> error)
        {
            PostDataFunction<int>("/notifctnservice", service, (Action<int>)success, (Action<string>)error);
        }

        public void NotificationServiceEdit(int id, NotificationService service, Action<bool> success, Action<string> error)
        {
            PutDataFunction<bool>("/notifctnservice/" + id, service, (Action<bool>)success, (Action<string>)error);
        }

        public void NotificationServiceDelete(int id, Action<bool> success, Action<string> error)
        {
            DeleteDataFunction<bool>("/notifctnservice/" + id, (Action<bool>)success, (Action<string>)error);
		}
		public void NotificationServiceTest(int userid, int serviceid, Action<bool> success, Action<string> error)
		{
            GetDataFunction<bool>("/notifctnservice/" + serviceid + "/test/" + userid, (Action<bool>)success, (Action<string>)error);
        }
        public void NotificationServiceInfo(int serviceid, Action<string> success, Action<string> error)
        {
            GetDataFunction<string>("/notifctnservice/" + serviceid + "/info", (Action<string>)success, (Action<string>)error);
        }
    }
}
