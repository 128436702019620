using System;
using Bridge;
using Bridge.Html5;
using Bridge.jQuery2;

namespace VisuPlusWebApp.Classes
{
    public partial class RestServerConnection : IServerConnection
    {

        private JSON.JSONBuilder _jsonBuilder;

        private string _apiKey;


        public RestServerConnection(string apiKey)
        {
            _apiKey = apiKey;
            _jsonBuilder = new JSON.JSONBuilder(
                new JSON.IJSONObjectConverter []  {
                    new JSON.TrendSettingsBuilder(),
                    new JSON.NotificationServiceBuilder(),
                    new JSON.StationSettingsBuilder(),
                    new JSON.DbSystemTreeBuilder(),
                    new JSON.CommSymbolBuilder()
                } );
        }


        public static void VisuPlusGetApiKey(Action<string> success, Action<string> error)
        {
            jQuery.Ajax(new AjaxOptions()
            {
                Success = (o, s, arg3) =>
                {
                    success(o.ToString());
                },
                Error = (xhr, s, arg3) =>
                {
                    //try get message
                    string errorString = string.Empty;
                    try
                    {
                        var jEr = Bridge.Html5.JSON.Parse(xhr.ResponseText).ToDynamic();
                        errorString = jEr["Message"] + "<br>---------------------<br>" + jEr["ExceptionType"] + jEr["ExceptionMessage"];
                    }
                    catch
                    {
                        errorString = xhr.ResponseText;
                    }
                    #if Debug
                    //Console.WriteLine(errorString);
                    #endif
                    error(errorString);
                },
                Url = "/api/random",
                Cache = false,
                Type = "GET",
                DataType = "text"
            });
        }

        public static void GetAppVersion(Action<string> success, Action<string> error)
        {
            jQuery.Ajax(new AjaxOptions()
            {
                Success = (o, s, arg3) =>
                {
                    success(o.ToString());
                },
                Error = (xhr, s, arg3) =>
                {
                    //try get message
                    string errorString = string.Empty;
                    try
                    {
                        var jEr = Bridge.Html5.JSON.Parse(xhr.ResponseText).ToDynamic();
                        errorString = jEr["Message"] + "<br>---------------------<br>" + jEr["ExceptionType"] + jEr["ExceptionMessage"];
                    }
                    catch
                    {
                        errorString = xhr.ResponseText;
                    }
#if Debug
                    //Console.WriteLine(errorString);
#endif
                    error(errorString);
                },
                Url = "/version.txt",
                Cache = false,
                Type = "GET",
                DataType = "text"
            });
        }
        


        private void PostDataFunction<T>(string url, object data, Action<T> success, Action<string> error)
        {
            //if (data.GetType().IsGenericType)
            //{
            //    data = "=" + data.ToString();
            //}
            jQuery.Ajax(new AjaxOptions()
            {
                Success = (o, s, arg3) =>
                {
                    var jObj =  Bridge.Html5.JSON.Parse(o.ToString());
                    success((T)_jsonBuilder.ConvertObject(jObj, typeof(T)));
                },
                Error = (xhr, s, arg3) =>
                {
                    //try get message
                    string errorString = string.Empty;
                    try
                    {
                        var jEr = Bridge.Html5.JSON.Parse(xhr.ResponseText).ToDynamic();
                        errorString = jEr["Message"] + "<br>---------------------<br>" + jEr["ExceptionType"] + jEr["ExceptionMessage"];
                    }
                    catch
                    {
                        errorString = xhr.ResponseText;
                    }

#if Debug
                    //Console.WriteLine(errorString);
#endif
                    error(errorString);
                },
                Url = "/api" + url,
                Cache = false,
                Type = "POST",
                DataType = "text",
                Data = Newtonsoft.Json.JsonConvert.SerializeObject(data),
                ContentType = "application/json; charset=utf-8",
                BeforeSend = (xhr, o) =>
                {
                    xhr.SetRequestHeader("Authorization", "" + _apiKey);
                    return true;
                },

            });
        }
        private void PostDataFunctionBlob(string url, object data, Action<Blob> success, Action<string> error)
        {
            //if (data.GetType().IsGenericType)
            //{
            //    data = "=" + data.ToString();
            //}
            
//            jQuery.Ajax(new AjaxOptions()
//            {
//                Success = (o, s, arg3) =>
//                {
                    
//                    success(new Blob(new BlobDataObject[] {(BlobDataObject) o}));
                    
//                },
//                Error = (xhr, s, arg3) =>
//                {
//                    //try get message
//                    string errorString = string.Empty;
//                    try
//                    {
//                        var jEr = JSON.Parse(xhr.ResponseText).ToDynamic();
//                        errorString = jEr["Message"] + "<br>---------------------<br>" + jEr["ExceptionType"] + jEr["ExceptionMessage"];
//                    }
//                    catch
//                    {
//                        errorString = xhr.ResponseText;
//                    }

//#if Debug
//                    //Console.WriteLine(errorString);
//#endif
//                    error(errorString);
//                },
//                Url = "/api" + url,
//                Cache = false,
//                Type = "POST",
//                Data = JSON.Stringify(data),
//                ContentType = "application/json; charset=utf-8",
//                BeforeSend = (xhr, o) =>
//                {
//                    xhr.SetRequestHeader("Authorization", "" + _apiKey);
//                    xhr.ResponseType = XMLHttpRequestResponseType.Blob;
//                    o.ToDynamic().processData = false;
//                    return true;
//                }
//            });

            var xhrReq = new XMLHttpRequest();
            xhrReq.Open("POST", "/api" + url, true);
            xhrReq.OnLoad =(args) =>
            {
                if(xhrReq.Status == 200)
                {
                    success(new Blob(new BlobDataObject[] { (BlobDataObject)xhrReq.Response }));
                }
                else
                {
                    error("error status-code:" + xhrReq.Status + " - " + xhrReq.StatusText);
                }
                
            };
            xhrReq.OnError += (err) =>
            {
                error("error status-code:" + xhrReq.Status + " - " +xhrReq.StatusText);
            };
            xhrReq.SetRequestHeader("Authorization", "" + _apiKey);
            xhrReq.SetRequestHeader("Content-type", "application/json; charset=utf-8");
            xhrReq.ResponseType = XMLHttpRequestResponseType.Blob;
            xhrReq.Send(Newtonsoft.Json.JsonConvert.SerializeObject(data));

        }
        private void GetTextFunction(string url, Action<string> success, Action<string> error, Action<int> percent = null) {

            jQuery.Ajax(new AjaxOptions()
            {
                Success = (o, s, arg3) =>
                {
                    success(o.ToString());
                },
                Error = (xhr, s, arg3) =>
                {
                    //try get message
                    string errorString = string.Empty;
                    try
                    {
                        var jEr = Bridge.Html5.JSON.Parse(xhr.ResponseText).ToDynamic();
                        errorString = jEr["Message"] + "<br>---------------------<br>" + jEr["ExceptionType"] + jEr["ExceptionMessage"];
                    }
                    catch
                    {
                        errorString = xhr.ResponseText;
                    }
#if Debug
                        //Console.WriteLine(errorString);
#endif
                    error(errorString);
                },
                Xhr = () => new XMLHttpRequest()
                {            
                    OnProgress = @event =>
                    {
                        if (percent != null)
                        {
                            object loaded = @event["loaded"];
                            object total = @event["total"];

                            int iLoaded = 1;
                            int iTotal = 1;
                            int.TryParse(loaded.ToString(), out iLoaded);
                            int.TryParse(total.ToString(), out iTotal);
                            percent((int)((float)iLoaded / (float)iTotal) * 100);
                        }
                    }
                },
                Url = "/api" + url,
                Cache = false,
                Type = "GET",
                DataType = "text",
                BeforeSend = (xhr, o) =>
                {
                    xhr.SetRequestHeader("Authorization", "" + _apiKey);
                    return true;
                }
            });
        }
        private void GetTextPostFunction(string url, object data, Action<string> success, Action<string> error, Action<int> percent = null)
        {
            jQuery.Ajax(new AjaxOptions()
            {
                Success = (o, s, arg3) =>
                {
                    success(o.ToString());
                },
                Error = (xhr, s, arg3) =>
                {
                    //try get message
                    string errorString = string.Empty;
                    try
                    {
                        var jEr = Bridge.Html5.JSON.Parse(xhr.ResponseText).ToDynamic();
                        errorString = jEr["Message"] + "<br>---------------------<br>" + jEr["ExceptionType"] + jEr["ExceptionMessage"];
                    }
                    catch
                    {
                        errorString = xhr.ResponseText;
                    }
#if Debug
                        //Console.WriteLine(errorString);
#endif
                    error(errorString);
                },
                Xhr = () => new XMLHttpRequest()
                {
                    OnProgress = @event =>
                    {
                        if (percent != null)
                        {
                            object loaded = @event["loaded"];
                            object total = @event["total"];

                            int iLoaded = 1;
                            int iTotal = 1;
                            int.TryParse(loaded.ToString(), out iLoaded);
                            int.TryParse(total.ToString(), out iTotal);
                            percent((int)((float)iLoaded / (float)iTotal) * 100);
                        }
                    }
                },
                Url = "/api" + url,
                Cache = false,
                Type = "POST",
                DataType = "text",
                Data = Newtonsoft.Json.JsonConvert.SerializeObject(data),
                ContentType = "application/json; charset=utf-8",
                BeforeSend = (xhr, o) =>
                {
                    xhr.SetRequestHeader("Authorization", "" + _apiKey);
                    return true;
                }
            });

        }
        

        private void GetDataFunction<T>(string url, Action<T> success, Action<string> error)
        {
            jQuery.Ajax(new AjaxOptions()
            {
                Success = (o, s, arg3) =>
                {
                    var jObj = Bridge.Html5.JSON.Parse(o.ToString());
                    success((T)_jsonBuilder.ConvertObject(jObj, typeof(T)));
                },
                Error = (xhr, s, arg3) =>
                {
                    //try get message
                    string errorString = string.Empty;
                    try
                    {
                        var jEr = Bridge.Html5.JSON.Parse(xhr.ResponseText).ToDynamic();
                        errorString = jEr["Message"] + "<br>---------------------<br>" + jEr["ExceptionType"] + jEr["ExceptionMessage"];
                    }
                    catch
                    {
                        errorString = xhr.ResponseText;
                    }

                    #if Debug
                        //Console.WriteLine(errorString);
                    #endif

                    error(errorString);
                },
                Url = "/api" + url,
                Cache = false,
                Type = "GET",
                DataType = "text",
                BeforeSend = (xhr, o) =>
                {
                    xhr.SetRequestHeader("Authorization", "" + _apiKey);
                    return true;
                },
                //Converters = new { textjson = _reviver } //has unicode 200E ->‎<- for text json
            });
        }
        private void PutDataFunction<T>(string url, object data, Action<T> success, Action<string> error)
        {
            //if (data.GetType().IsGenericType)
            //{
            //    data = "=" + data.ToString();
            //}
            jQuery.Ajax(new AjaxOptions()
            {
                Success = (o, s, arg3) =>
                {
                    var jObj = Bridge.Html5.JSON.Parse(o.ToString());
                    success((T)_jsonBuilder.ConvertObject(jObj, typeof(T)));
                },
                Error = (xhr, s, arg3) =>
                {
                    //try get message
                    string errorString = string.Empty;
                    try
                    {
                        var jEr = Bridge.Html5.JSON.Parse(xhr.ResponseText).ToDynamic();
                        errorString = jEr["Message"] + "<br>---------------------<br>" + jEr["ExceptionType"] + jEr["ExceptionMessage"];
                    }
                    catch
                    {
                        errorString = xhr.ResponseText;
                    }
#if Debug
                    //Console.WriteLine(errorString);
#endif
                    error(errorString);
                },
                Url = "/api" + url,
                Cache = false,
                Type = "PUT",
                DataType = "text",
                Data = Newtonsoft.Json.JsonConvert.SerializeObject(data),
                ContentType = "application/json; charset=utf-8",
                BeforeSend = (xhr, o) =>
                {
                    xhr.SetRequestHeader("Authorization", "" + _apiKey);
                    return true;
                },
                //Converters = new { textjson = _reviver } //has unicode 200E ->‎<- for text json
            });
        }
        private void DeleteDataFunction<T>(string url, Action<T> success, Action<string> error)
        {
            jQuery.Ajax(new AjaxOptions()
            {
                Success = (o, s, arg3) =>
                {
                    var jObj = Bridge.Html5.JSON.Parse(o.ToString());
                    success((T)_jsonBuilder.ConvertObject(jObj, typeof(T)));
                },
                Error = (xhr, s, arg3) =>
                {
                    //try get message
                    string errorString = string.Empty;
                    try
                    {
                        var jEr = Bridge.Html5.JSON.Parse(xhr.ResponseText).ToDynamic();
                        errorString = jEr["Message"] + "<br>---------------------<br>" + jEr["ExceptionType"] + jEr["ExceptionMessage"];
                    }
                    catch
                    {
                        errorString = xhr.ResponseText;
                    }
#if Debug
                    //Console.WriteLine(errorString);
#endif
                    error(errorString);
                },
                Url = "/api" + url,
                Cache = false,
                Type = "DELETE",
                DataType = "text",
                BeforeSend = (xhr, o) =>
                {
                    xhr.SetRequestHeader("Authorization", "" + _apiKey);
                    return true;
                },
                //Converters = new { textjson = _reviver } //has unicode 200E ->‎<- for text json
            });
        }
        private void GetDataWithTransferInfoFunction<T>(string url, Action<T> success, Action<int> percent, Action<string> error)
        {
            jQuery.Ajax(new AjaxOptions()
            {
                Success = (o, s, arg3) =>
                {
                    var jObj = Bridge.Html5.JSON.Parse(o.ToString());
                    success((T)_jsonBuilder.ConvertObject(jObj, typeof(T)));
                },
                Error = (xhr, s, arg3) =>
                {
                    //try get message
                    string errorString = string.Empty;
                    try
                    {
                        var jEr = Bridge.Html5.JSON.Parse(xhr.ResponseText).ToDynamic();
                        errorString = jEr["Message"] + "<br>---------------------<br>" + jEr["ExceptionType"] + jEr["ExceptionMessage"];
                    }
                    catch
                    {
                        errorString = xhr.ResponseText;
                    }
                    #if Debug
                        //Console.WriteLine(errorString);
                    #endif
                    error(errorString);
                },
                Xhr = () => new XMLHttpRequest()
                {
                    OnProgress = @event =>
                    {
                        object loaded = @event["loaded"];
                        object total = @event["total"];

                        int iLoaded = 1;
                        int iTotal = 1;
                        int.TryParse(loaded.ToString(), out iLoaded);
                        int.TryParse(total.ToString(), out iTotal);
                        percent((int)((float)iLoaded / (float)iTotal) * 100);
                    }
                },
                Url = "/api" + url,
                Cache = false,
                Type = "GET",
                DataType = "text",
                BeforeSend = (xhr, o) =>
                {
                    xhr.SetRequestHeader("Authorization", "" + _apiKey);
                    return true;
                },
                //Converters = new { textjson = _reviver } //has unicode 200E ->‎<- for text json
            });
            
        }
        private void PostDataWithTransferFunction<T>(string url, object data, Action<T> success, Action<int> percent, Action<string> error)
        {
            //if (data.GetType().IsGenericType)
            //{
            //    data = "=" + data.ToString();
            //}
            jQuery.Ajax(new AjaxOptions()
            {
                Success = (o, s, arg3) =>
                {
                    var jObj = Bridge.Html5.JSON.Parse(o.ToString());
                    success((T)_jsonBuilder.ConvertObject(jObj, typeof(T)));
                },
                Error = (xhr, s, arg3) =>
                {
                    //try get message
                    string errorString = string.Empty;
                    try
                    {
                        var jEr = Bridge.Html5.JSON.Parse(xhr.ResponseText).ToDynamic();
                        errorString = jEr["Message"] + "<br>---------------------<br>" + jEr["ExceptionType"] + jEr["ExceptionMessage"];
                    }
                    catch
                    {
                        errorString = xhr.ResponseText;
                    }
#if Debug
                   // Console.WriteLine(errorString);
#endif
                    error(errorString);
                },
                Xhr = () => new XMLHttpRequest()
                {
                    OnProgress = @event =>
                    {
                        object loaded = @event["loaded"];
                        object total = @event["total"];

                        int iLoaded = 1;
                        int iTotal = 1;
                        int.TryParse(loaded.ToString(), out iLoaded);
                        int.TryParse(total.ToString(), out iTotal);
                        percent((int)((float)iLoaded / (float)iTotal) * 100);
                    }
                },
                Url = "/api" + url,
                Cache = false,
                Type = "POST",
                DataType = "text",
                Data = Newtonsoft.Json.JsonConvert.SerializeObject(data),
                ContentType = "application/json; charset=utf-8",
                BeforeSend = (xhr, o) =>
                {
                    xhr.SetRequestHeader("Authorization", "" + _apiKey);
                    return true;
                },
                //Converters = new { textjson = _reviver } //has unicode 200E ->‎<- for text json
            });
        }

    }
}
