using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Bridge.Html5;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes
{
    public partial class RestServerConnection : IServerConnection
    {

        public void FaultDataGetAll(Action<FaultDataItem[]> success, Action<string> error)
        {
            GetDataFunction<FaultDataItem[]>("/faults", (Action<FaultDataItem[]>)success, (Action<string>)error);
        }

        public void FaultDataGetById(FaultId id, Action<FaultDataItem> success, Action<string> error)
        {
            GetDataFunction<FaultDataItem>("/faults/"+ id.Station + "/" + id.Bit , (Action<FaultDataItem>)success, (Action<string>)error);
        }

        public void FaultDataAddSingle(FaultDataItem item, Action<FaultId> success, Action<string> error)
        {
            PostDataFunction<FaultId>("/faults", item, (Action<FaultId>)success, (Action<string>)error);
        }
        
        public void FaultDataAddMultiple(FaultDataItem[] items, Action<bool> success, Action<string> error)
        {
            
            PostDataFunction<bool>("/faults/multiple", items, (Action<bool>)success, (Action<string>)error);
        }
        
        public void FaultDataEdit(FaultDataItem item, Action<bool> success, Action<string> error)
        {
            PutDataFunction<bool>("/faults/" + item.Station + "/" + item.Bit, item, (Action<bool>)success, (Action<string>)error);
        }

        public void FaultDataDelete(FaultId id, Action<bool> success, Action<string> error)
        {
            DeleteDataFunction<bool>("/faults/" + id.Station + "/" + id.Bit, (Action<bool>)success, (Action<string>)error);
        }
    }
}
