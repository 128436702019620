using System.ComponentModel.DataAnnotations;

namespace RGAVerl.VisuPlus2000net.Models
{
    public class NotificationServiceOutput : NotificationService
    {
        public override NotificationServiceProvider ServiceType
        {
            set { }
            get
            {
                return NotificationServiceProvider.Output;
            }
        }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Station { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Address { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Value { set; get; }
        public int ResetAfterSeconds { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string ResetValue { set; get; }



    }
}
