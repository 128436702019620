using System.ComponentModel.DataAnnotations;

namespace RGAVerl.VisuPlus2000net.Models
{
    /// <summary>
    /// an identfier for faults 
    /// with station and bit
    /// </summary>
    /// this can be also an unknown fault without an real id
    public class FaultId
    {
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Station { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Bit { set; get; }

        public FaultId()
        {
        }

        public FaultId(string station, string bit)
        {
            Station = station;
            Bit = bit;
        }

        public override string ToString()
        {
            return "STATION_" + Station + "__BIT_" + Bit;
        }

        public override bool Equals(object obj)
        {
            return (obj is FaultId) && (((FaultId)obj).Station.Equals(this.Station)) &&
                    (((FaultId)obj).Bit.Equals(this.Bit));
        }

        protected bool Equals(FaultId other)
        {
            return string.Equals(Station, other.Station) && string.Equals(Bit, other.Bit);
        }

        public override int GetHashCode()
        {
            unchecked
            {
                return ((Station != null ? Station.GetHashCode() : 0) * 397) ^ (Bit != null ? Bit.GetHashCode() : 0);
            }
        }
    }
}
