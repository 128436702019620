using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes
{
    public partial class RestServerConnection : IServerConnection
    {


        public void FaultGroupsGetAll(Action<FaultGroup[]> success, Action<string> error)
        {
            GetDataFunction<FaultGroup[]>("/faultgroups", (Action<FaultGroup[]>)success, (Action<string>)error);
        }

        public void FaultGroupsGetById(int id, Action<FaultGroup> success, Action<string> error)
        {
            GetDataFunction<FaultGroup>("/faultgroups/"+id, (Action<FaultGroup>)success, (Action<string>)error);
        }

        public void FaultGroupsAdd(FaultGroup faultGroup, Action<int> success, Action<string> error)
        {
            PostDataFunction<int>("/faultgroups", faultGroup, (Action<int>)success, (Action<string>)error);
        }

        public void FaultGroupsEdit(int id, FaultGroup faultGroup, Action<bool> success, Action<string> error)
        {
            PutDataFunction<bool>("/faultgroups/" +id, faultGroup, (Action<bool>)success, (Action<string>)error);
        }

        public void FaultGroupsDelete(int id, Action<bool> success, Action<string> error)
        {
            DeleteDataFunction<bool>("/faultgroups/" + id, (Action<bool>)success, (Action<string>)error);
        }




        public void FaultGroupsFaultsGet(int id, Action<FaultDataItem[]> success, Action<string> error)
        {
            GetDataFunction<FaultDataItem[]>("/faultgroups/" + id + "/faults", (Action<FaultDataItem[]>)success, (Action<string>)error);
        }

        public void FaultGroupsFaultsGetNotForGroup(int id, Action<FaultDataItem[]> success, Action<string> error)
        {
            GetDataFunction<FaultDataItem[]>("/faultgroups/" + id + "/notfaults", (Action<FaultDataItem[]>)success, (Action<string>)error);
        }

        public void FaultGroupsFaultsAdd(int groupId, FaultId faultId, Action<bool> success, Action<string> error)
        {
            PostDataFunction<bool>("/faultgroups/" + groupId + "/faults", faultId, (Action<bool>)success, (Action<string>)error);
        }

        public void FaultGroupsFaultsDelete(int groupId, FaultId id, Action<bool> success, Action<string> error)
        {
            PostDataFunction<bool>("/faultgroups/" + groupId + "/faults/delete", id, (Action<bool>)success, (Action<string>)error);
        }




        public void FaultGroupsUserGet(int id, Action<User[]> success, Action<string> error)
        {
            GetDataFunction<User[]>("/faultgroups/" + id + "/users", (Action<User[]>)success, (Action<string>)error);
        }

        public void FaultGroupsUserGetNotForGroup(int id, Action<User[]> success, Action<string> error)
        {
            GetDataFunction<User[]>("/faultgroups/" + id + "/notusers", (Action<User[]>)success, (Action<string>)error);
        }

        public void FaultGroupsUserAdd(int groupId, int userId, Action<bool> success, Action<string> error)
        {
            PostDataFunction<bool>("/faultgroups/" + groupId + "/users", userId, (Action<bool>)success, (Action<string>)error);
        }

        public void FaultGroupsUserDelete(int groupId, int userId, Action<bool> success, Action<string> error)
        {
            DeleteDataFunction<bool>("/faultgroups/" + groupId + "/users/"+ userId, (Action<bool>)success, (Action<string>)error);
        }




        public void FaultGroupsUserNotificationsGet(int groupId, int userId, Action<FaultGroupUserNotification[]> success, Action<string> error)
        {
            GetDataFunction<FaultGroupUserNotification[]>("/faultgroups/" + groupId + "/users/" +userId + "/notifications", (Action<FaultGroupUserNotification[]>)success, (Action<string>)error);
        }

        public void FaultGroupsUserNotificationsAdd(int groupId, int userId, FaultGroupUserNotification notification, Action<bool> success,
            Action<string> error)
        {
            PostDataFunction<bool>("/faultgroups/" + groupId + "/users/" + userId + "/notifications", notification, (Action<bool>)success, (Action<string>)error);
        }

        public void FaultGroupsUserNotificationsDelete(int groupId, int userId, int notificationid, Action<bool> success, Action<string> error)
        {
            DeleteDataFunction<bool>("/faultgroups/" + groupId + "/users/" + userId + "/notifications/" + notificationid, (Action<bool>)success, (Action<string>)error);
        }
    }
}
