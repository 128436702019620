using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace RGAVerl.VisuPlus2000net.Models
{
    public class TrendDatabaseSettingsNone : TrendDatabaseSettings
    {
        public override TrendSettingsProvider Provider
        {
            set { }
            get
            {
                return TrendSettingsProvider.None;
            }
        }
    }
}
