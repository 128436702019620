using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using RGAVerl.VisuPlus2000net.Models;

namespace VisuPlusWebApp.Classes
{
    public partial class RestServerConnection : IServerConnection
    {
        public void CommunicationSymbolGetAll(Action<CommunicationSymbol[]> success, Action<string> error)
        {
            GetDataFunction<CommunicationSymbol[]>("/communication/symbols", (Action<CommunicationSymbol[]>)success, (Action<string>)error);
        }

        public void CommunicationSymbolGetAllForStation(string station, Action<CommunicationSymbol[]> success, Action<string> error)
        {
            GetDataFunction<CommunicationSymbol[]>("/communication/symbolsforstation/"+ station, (Action<CommunicationSymbol[]>)success, (Action<string>)error);
        }

        public void CommunicationSymbolGetById(int id, Action<CommunicationSymbol> success, Action<string> error)
        {
            GetDataFunction<CommunicationSymbol>("/communication/symbols/" + id, (Action<CommunicationSymbol>)success, (Action<string>)error);
        }

        public void CommunicationSymbolAdd(CommunicationSymbol symbol, Action<int> success, Action<string> error)
        {
            PostDataFunction<int>("/communication/symbols", symbol, (Action<int>)success, (Action<string>)error);
        }

        public void CommunicationSymbolEdit(int id, CommunicationSymbol symbol, Action<bool> success, Action<string> error)
        {
            PutDataFunction<bool>("/communication/symbols/" + id, symbol, (Action<bool>)success, (Action<string>)error);
        }

        public void CommunicationSymbolDelete(int id, Action<bool> success, Action<string> error)
        {
            DeleteDataFunction<bool>("/communication/symbols/" + id, (Action<bool>)success, (Action<string>)error);
        }

        public void CommunicationSymbolDeleteAllForStation(string station, Action<bool> success, Action<string> error)
        {
            DeleteDataFunction<bool>("/communication/symbolsforstation/" + station, (Action<bool>)success, (Action<string>)error);
        }
        

    }

}

