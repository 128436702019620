using System;
using System.ComponentModel.DataAnnotations;

namespace RGAVerl.VisuPlus2000net.Models
{
    //information about the actual project 
    //needs to be saved with the project -> database
    #region projectsettings

    public class ProjectSettings
    {
        //infos
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string ProjectName { set; get; } //actual name of the project
        public int AutoLogoutMinutes { set; get; } //user gets logged out after minutes
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string ProjectImageTextColor { set; get; } //color of the text above the project image, useful if image is to dark 
        public bool HideLoginUser { set; get; }

        //faults
        public bool FaultGroupOverwritesColor { set; get; } //the color of a faultgroup overwrites the color of fault
        public bool FaultGroupOverwritesInfotext { set; get; } //the infotext of a faultgroup overwrites the infotext of fault

        //windows
        public int WindowSizeHeight { set; get; } //default size of system-windows
        public int WindowSizeWidth { set; get; } //default size of system-windows
        public float ImageScalingFactor { set; get; } //scales down or up images on systemwindows
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string DefaultWindowBackColor { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string DataErrorText { set; get; } //error-text 
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string DataWaitingText { set; get; } //waiting text
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string DataMaintanceText { set; get; } //waiting text
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string DataErrorForeColor { set; get; } //color for error-texts
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string DataErrorBackColor { set; get; } //color for error-texts
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string DataLockedValueForeColor { set; get; } //color for locked-values -> actual values
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string DataLockedValueBackColor { set; get; } //color for locked-values 
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string DataChangeableValueForeColor { set; get; } //color for not locked values -> setpoints
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string DataChangeableValueBackColor { set; get; } //color for not locked values -> setpoints
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string DataMaintanceValueForeColor { set; get; } //color for not locked values -> setpoints
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string DataMaintanceValueBackColor { set; get; } //color for not locked values -> setpoints
        public double DivisorTwipsX { set; get; } //color for not locked values -> setpoints
        public double DivisorTwipsY { set; get; } //color for not locked values -> setpoints

        public int DataRefreshTime { set; get; } //time data gets refreshed
        public int DataTrailingTime { set; get; } //time for let the connection open after closing a window -> only need for modem
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string StationValueErrorString { set; get; } //if station has a error default string value
        public int DataMaxChacheAge { set; get; } //the max cache age for data in seconds
        public int DataMaxSymbolAge { set; get; } //the max time a symbol gets cached
        public int DataMaxSymbolReadTrys { set; get; } //the max trys a symbol gets read before error
        
        public bool SaveFaults { set; get; } // save active faults in file

        public int TrendMaxTimeDifference { set; get; } // max time differnce in trend reading

        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string MasterPassword { set; get; } //Master Password to reset other not level 0 passwords

        public bool AllowQuitFaultAsGuest { set; get; } //Master Password to reset other not level 0 passwords

        public ProjectSettings()
        {
            AutoLogoutMinutes = 0;
            DataChangeableValueBackColor = "#FFFFFF"; //
            DataChangeableValueForeColor = "#000000"; //Color.Black;
            DataErrorBackColor = "#FF0000"; //Color.Red;
            DataErrorForeColor = "#000000"; //Color.Black;
            DataErrorText = "???";
            DataLockedValueBackColor = "#D3D3D3"; //Color.LightGray;
            DataLockedValueForeColor = "#000000"; //Color.Black;
            DataMaintanceValueForeColor = "#FFFFFF"; // Color.White;
            DataMaintanceValueBackColor = "#0000FF"; //Color.Blue;
            DataRefreshTime = 3;
            DataTrailingTime = 0;
            ProjectName = "";
            ImageScalingFactor = 1;
            WindowSizeWidth = 800;
            WindowSizeHeight = 600;
            DataWaitingText = "---";
            DataMaintanceText = "!!!";
            StationValueErrorString = "ERROR";
            DefaultWindowBackColor = "#4169E1"; //Color.RoyalBlue;
            ProjectImageTextColor = "#000000"; //Color.Black;
            SaveFaults = false;
            MasterPassword = "R+GAService2016";
            DataMaxSymbolAge = 180;
            DataMaxSymbolReadTrys = 3;
            DataMaxChacheAge = 5;
            TrendMaxTimeDifference = 3;
            DivisorTwipsX = 9.8;
            DivisorTwipsY = 9.2;
            AllowQuitFaultAsGuest = false;
            FaultGroupOverwritesColor = false;
            FaultGroupOverwritesInfotext = false;
            HideLoginUser = false;
        }
    }

    #endregion


}
