using System;
using System.ComponentModel;

namespace RGAVerl.VisuPlus2000net.Models
{
    public class CommunicationSymbolAds : CommunicationSymbol
    {
        public string VarName { set; get; }
        public CommuncationSymbolAdsVarType DataType { set; get; }


        public override Models.CommunicationSymbolTypes SymbolType
        {
            get
            {
                return Models.CommunicationSymbolTypes.Ads;
            }
            set { }
        }

        public override string Information
        {
            get
            {
                return "Name: " +  VarName +  " Typ: " + DataType.ToString();
            }
            set { }
        }
        
    }
}
