using System;
using System.ComponentModel.DataAnnotations;

namespace RGAVerl.VisuPlus2000net.Models
{
    //an user with all information
    public class User
    {
        //id
        public int Id { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Name { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Description { set; get; }
        public DateTime LastLogin { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string FullName {
            get { return this.ToString(); } }
        public bool Active { set; get; }
        public UserRights Rights { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Mail { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Mobile { set; get; }
        [DisplayFormat(ConvertEmptyStringToNull = false)]
        public string Phone { set; get; }
        public Weekdays WorkingDays { set; get; }
        public int WorkingTimeStart { set; get; }
        public int WorkingTimeEnd { set; get; }

        
        public string GetLevelName()
        {
            if (this.Rights.HasFlag(UserRights.IsAdmin)) return "Admin";
            else if (this.Rights.HasFlag(UserRights.IsLevel0)) return "Level 0";
            else if (this.Rights.HasFlag(UserRights.IsLevel1)) return "Level 1";
            else if (this.Rights.HasFlag(UserRights.IsLevel2)) return "Level 2";
            else if (this.Rights.HasFlag(UserRights.IsLevel3)) return "Level 3";
            else if (this.Rights.HasFlag(UserRights.IsLevel4)) return "Level 4";
            else if (this.Rights.HasFlag(UserRights.IsLevel5)) return "Level 5";
            else if (this.Rights.HasFlag(UserRights.IsLevel6)) return "Level 6";
            else if (this.Rights.HasFlag(UserRights.IsLevel7)) return "Level 7";
            else if (this.Rights.HasFlag(UserRights.IsLevel8)) return "Level 8";
            else if (this.Rights.HasFlag(UserRights.IsLevel9)) return "Level 9";
            else if (this.Rights.HasFlag(UserRights.IsLevel10)) return "Level 10";
            else return "Kein";

        }
        public bool HasLevel(int level)
        {
            if (this.Rights.HasFlag(UserRights.IsAdmin)) return true;
            switch (level)
            {
                case 0:
                    return this.Rights.HasFlag(UserRights.IsLevel0);
                case 1:
                    return this.Rights.HasFlag(UserRights.IsLevel1);
                case 2:
                    return this.Rights.HasFlag(UserRights.IsLevel2);
                case 3:
                    return this.Rights.HasFlag(UserRights.IsLevel3);
                case 4:
                    return this.Rights.HasFlag(UserRights.IsLevel4);
                case 5:
                    return this.Rights.HasFlag(UserRights.IsLevel5);
                case 6:
                    return this.Rights.HasFlag(UserRights.IsLevel6);
                case 7:
                    return this.Rights.HasFlag(UserRights.IsLevel7);
                case 8:
                    return this.Rights.HasFlag(UserRights.IsLevel8);
                case 9:
                    return this.Rights.HasFlag(UserRights.IsLevel9);
                case 10:
                    return this.Rights.HasFlag(UserRights.IsLevel10);
                default:
                    return true;
            }
        }

        public override string ToString()
        {
            string name = this.Name;
            if (!Description.Equals(""))
            {
                name += " <" + this.Description + ">";
            }
            return name;
        }
    }
}
