using System.Collections.Generic;

namespace RGAVerl.VisuPlus2000net.Models
{
    //frame message for polling
    public class SessionFaultUpdateFault
    {
        public FaultTypes FaultType { set; get; }
        public SessionUserFault Fault { set; get; }

        public SessionFaultUpdateFault(FaultTypes faultType, SessionUserFault fault)
        {
            FaultType = faultType;
            Fault = fault;
        }
        public SessionFaultUpdateFault()
        {

        }
    }
}
